import React from "react";
import { useState, useEffect, useRef } from "react";
// import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import "./CarForm.css";
import carin from "../resources/in.svg";
// import Accordion from "react-bootstrap/Accordion";
// import question from "../resources/ques.svg";
import log from "../resources/Logo.svg";
import { useNavigate, useParams } from "react-router-dom";
import hambergar from "../resources/hambarger.svg";
import cross from "../resources/cross.svg";
import trans from "../resources/englishtohindi.svg";
import Footerlogo from "../resources/pollicydekhoo-Cdr-jpg-300x300 1 (1).png";
import Callsupport from "../resources/callsupport.svg";
import Callback from "../resources/callbacksupport.svg";
import Whatupsupport from "../resources/whatupsupport.svg";
import Mailsupport from "../resources/mailsupport.svg";
import { Modal, Box, Typography, Button, TextField } from "@mui/material";

function CarForm() {
  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const contactFormRef = useRef(null);

  // // Function to scroll to the contact form
  // const scrollToContactForm = () => {
  //   if (contactFormRef.current) {
  //     contactFormRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  useEffect(() => {
    console.log("Scrolling to the top");
    window.scrollTo(0, 0);
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  // const [VehicleNumber,setVehicleNumber] =useState("")
  const [InvalidRegiter, setInvalidRegiter] = useState(false);

  const validateStateVehicleNumber = (VehicleNumber) => {
    const regex = /^([A-Za-z]{2}[0-9]{2}[A-Za-z]{2}[0-9]{4})$/;

    if (VehicleNumber.match(regex)) {
      console.log("valid Number");
      return true;
    } else {
      console.log("invalid Number");
      return false;
    }
  };

  const [toogle, settoggle] = useState(false);

  const [selectedManufacturer, setSelectedManufacturer] = useState("");
  const [models, setModels] = useState([]);

  const handleManufacturerChange = (event) => {
    const manufacturer = event.target.value;
    setSelectedManufacturer(manufacturer);

    // Filter models based on selected manufacturer
    const filteredModels = getModelsForManufacturer(manufacturer);
    setModels(filteredModels);
    setCarInsuranceData({
      ...carInsuranceData,
      Manufacturer: event.target.value,
    });
  };

  const getModelsForManufacturer = (manufacturer) => {
    console.log("audi");
    const manufacturerModels = {
      Audi: [
        "Audi A4",
        "Audi Q3",
        "Audi e-tron GT",
        "Audi Q3 Sportback",
        "Audi S5 Sportback",
        "Audi Q5",
        "Audi Q7",
        "Audi A6",
        "Audi Q8",
        "Audi RS Q8",
        "Audi A8 L",
        "Audi RS5",
        "Audi Q8 e-tron",
        "Audi Q8 Sportback e-tron",
        "Audi Q8 Sportback e-tron",
        "Audi e-tron Sportback",
      ],
      BMW: [
        "BMW 7 Series",
        "BMW X1",
        "BMW 2 Series Gran Coupe",
        "BMW 3 Series Gran LimousineH",
        "BMW X7",
        "BMW Z4",
        "BMW M4 Competition",
        "BMW M340i",
        "BMW X5",
        "BMW M8",
        "BMW XM",
        "BMW 6 Series GT",
        "BMW M2",
        "BMW X3",
        "BMW X4 M40i",
        "BMW i7",
        "BMW i4",
        "BMW iX1",
        "BMW X5 M",
        "BMW X3 M40i",
        "BMW iX",
        "BMW X8",
      ],
      CHEVROLET: [
        "Chevrolet Beat",
        "Chevrolet Sail",
        "Chevrolet Spark",
        "Chevrolet Cruze",
        "Chevrolet Enjoy",
        "Chevrolet Tavera",
        "Chevrolet Captiva",
        "Chevrolet Trailblazer",
      ],
      FIAT: [
        "500 Lounge",
        "Abarth Punto",
        "Adventure",
        "Avventura",
        "Fiat Palio",
        "Grande Punto",
        "Linea",
        "Linea Classic",
        "Palio",
        "Palio Stile",
        "Palio Stile Multijet",
        "Petra",
        "Petra D",
        "Premier Padmini",
        "Punto",
        "Punto DL",
        "Siena",
        "Stile Multijet",
        "Uno",
      ],
      FORD: [
        "Aspire",
        "Classic",
        "EcoSport",
        "Ecosport",
        "Endeavour",
        "Fiesta",
        "Fiesta Classic",
        "Figo",
        "Figo Aspire",
        "Freestyle",
        "Fusion",
        "Ikon",
        "Mondeo",
        "Mustang",
      ],
      HONDA: [
        "Accord",
        "BR-V",
        "Brio",
        "Amaze",
        "City",
        "Civic",
        "CRV",
        "Jazz",
        "Mobilio",
        "New Civic",
        "New Jazz",
        "WR-V",
      ],
      HYUNDAI: [
        "Accent",
        "Aura",
        "Creta",
        "Elantra",
        "Elite i20",
        "Eon",
        "Verna",
        "Getz",
        "Grand i10",
        "i10",
        "i20",
        "Kona",
        "New Santro",
        "Santa Fe",
        "Santro",
        "Sonata",
        "Tucson",
        "Venue",
        "Xcent",
      ],
      JAGUAR: [
        "Jaguar F-PACE",
        "Jaguar F-TYPE",
        "Jaguar F-PACE",
        "Jaguar XE",
        "Jaguar XF",
        "Jaguar XJ",
        "Jaguar XKR",
      ],
      VOLVO: [
        "Volvo S90",
        "Volvo S60",
        "Volvo S80",
        "Volvo S90",
        "Volvo V40",
        "Volvo V40 D3",
        "Volvo V90",
        "Volvo XC40",
        "Volvo XC60",
        "Volvo XC90",
        "Volvo XC40",
        "Volvo XC60",
        "Volvo XC90",
      ],
      JEEP: ["Jeep Compass", "Jeep Grand Cherokee", "Jeep Wrangler"],
      LANDROVER: [
        "Land Rover Discovery",
        "Land Rover Discovery 3",
        "Land Rover Discovery 4",
        "Land Rover Discovery Sport",
        "Land Rover Freelander",
        "Land Rover Freelander 2",
        "Land Rover Range Rover",
        "Land Rover Range Rover Evoque",
        "Land Rover Range Rover Sport",
        "Land Rover Range Rover Velar",
      ],
      KIA: ["KIA CARNIVAL", "KIA SELTOS"],
      MAHINDRA: [
        "Mahindra Alturas G4",
        "Mahindra Bolero",
        "Mahindra Centuro",
        "Mahindra KUV 100",
        "Mahindra TUV300",
        "Mahindra Marazzo",
        "Mahindra Quanto",
        "Mahindra Rexton",
        "Mahindra Scorpio",
        "Mahindra Thar",
        "Mahindra TUV300",
        "Mahindra Verito",
        "Mahindra Verito Vibe",
        "Mahindra XUV300",
        "Mahindra XUV500",
        "Mahindra Xylo",
        "Mahindra Logan",
      ],
      MARUTI: [
        "Maruti Suzuki 800",
        "Maruti Suzuki Alto",
        "Maruti Suzuki Alto 800",
        "Maruti Suzuki Baleno",
        "Maruti Suzuki New Wagon R",
        "Maruti Suzuki Celerio",
        "Maruti Suzuki Celerio X",
        "Maruti Suzuki Ciaz",
        "Maruti Suzuki Dzire",
        "Maruti Suzuki Eeco",
        "Maruti Suzuki Ertiga",
        "Maruti Suzuki Ignis",
        "Maruti Suzuki Grand Vitara",
        "Maruti Suzuki Omni",
        "Maruti Suzuki Ritz",
        "Maruti Suzuki S-Cross",
        "Maruti Suzuki Swift",
        "Maruti Suzuki Swift Dzire",
        "Maruti Suzuki SX4",
        "Maruti Suzuki Vitara Brezza",
        "Maruti Suzuki Wagon R",
        "Maruti Suzuki Zen Estilo",
      ],
      RENAULT: [
        "Renault Koleos",
        "Renault Duster",
        "Renault Fluence",
        "Renault Triber",
        "Renault Kwid",
        "Renault Scala",
        "Renault Lodgy",
        "Renault Pulse",
      ],
      SKODA: [
        "Skoda Fabia",
        "Skoda Karoq",
        "Skoda Kodiaq",
        "Skoda Laura",
        "Skoda New Octavia",
        "Skoda Octavia",
        "Skoda Rapid",
        "Skoda Superb",
        "Skoda Yeti",
      ],
      TATA: [
        "Tata Altroz",
        "Tata Aria",
        "Tata Bolt",
        "Tata Harrier",
        "Tata Hexa",
        "Tata Tigor",
        "Tata Venture",
        "Tata Winger",
        "Tata Xenon",
        "Tata Zest",
        "Tata Nexon",
        "Tata Tiago",
        "Tata Safari",
      ],
      TOYOTA: [
        "Toyota Alphard",
        "Toyota Camry",
        "Toyota Corolla",
        "Toyota Etios",
        "Toyota Fortuner",
        "Toyota Glanza",
        "Toyota Harrier",
        "Toyota Hiace",
        "Toyota Innova",
        "Toyota Innova Crysta",
        "Toyota Land Cruiser",
        "Toyota Land Cruiser Prado",
        "Toyota Lexus",
        "Toyota Prius",
        "Toyota Qualis",
        "Toyota Yaris",
      ],
      VOLKSWAGEN: [
        "Volkswagen Ameo",
        "Volkswagen Beetle",
        "Volkswagen Cross Polo",
        "Volkswagen Jetta",
        "Volkswagen Passat",
        "Volkswagen Phaeton",
        "Volkswagen Polo",
        "Volkswagen T-Roc",
        "Volkswagen T5 Multivan",
        "Volkswagen Tiguan",
        "Volkswagen Touareg",
        "Volkswagen Vento",
      ],
      MERCEDES: [
        "Mercedes-Benz 140 D",
        "Mercedes-Benz A-Class",
        "Mercedes-Benz AMG",
        "Mercedes-Benz B-Class",
        "Mercedes-Benz 190",
        "Mercedes-Benz C-Class",
        "Mercedes-Benz CLA",
        "Mercedes-Benz CLK-Class",
        "Mercedes-Benz CLS",
        "Mercedes-Benz CLS-Class",
        "Mercedes-Benz E-Class",
        "Mercedes-Benz G-Class",
        "Mercedes-Benz GL",
        "Mercedes-Benz GL-Class",
        "Mercedes-Benz GLA-Class",
        "Mercedes-Benz GLC",
        "Mercedes-Benz GLC-Class",
        "Mercedes-Benz GLE-Class",
        "Mercedes-Benz GLS",
        "Mercedes-Benz GLS-Class",
        "Mercedes-Benz M-Class",
        "Mercedes-Benz Maybach",
        "Mercedes-Benz MB100",
        "Mercedes-Benz ML-Class",
        "Mercedes-Benz R-Class",
        "Mercedes-Benz S 600 Maybach",
        "Mercedes-Benz S-Class",
        "Mercedes-Benz S-Class",
        "Mercedes-Benz SL-Class",
        "Mercedes-Benz SLK-Class",
        "Mercedes-Benz SLS-Class",
        "Mercedes-Benz V-Class",
      ],
      NISSAN: [
        "Nissan 370Z",
        "Datsun Go",
        "Datsun Redi Go",
        "Nissan Evalia",
        "Nissan GT-R",
        "Nissan Jonga",
        "Nissan Kicks",
        "Nissan Micra",
        "Nissan GT-R",
        "Nissan Sunny",
        "Nissan Teana",
        "Nissan Terrano",
        "Nissan X-Trail",
      ],
      ISUZU: [
        "Isuzu D-Max",
        "Isuzu D-Max V-Cross",
        "Isuzu MU-X",
        "Isuzu Trooper",
        "Isuzu Rodeo",
        "Isuzu Ascender",
        "Isuzu Axiom",
        "Isuzu Amigo",
        "Isuzu Hombre",
        "Isuzu i-Series",
        "Isuzu Gemini",
        "Isuzu P'up",
        "Isuzu Bellel",
        "Isuzu Fargo",
        "Isuzu Florian",
        "Isuzu Piazza",
        "Isuzu Impulse",
        "Isuzu Oasis",
        "Isuzu Stylus",
        "Isuzu 117 Coupe",
        "Isuzu Vertex",
        "Isuzu Aska",
        "Isuzu Axiom",
        "Isuzu Faster",
        "Isuzu Filly",
        "Isuzu Forward",
        "Isuzu Gala",
        "Isuzu Giga",
        "Isuzu H-Series",
        "Isuzu Journey",
        "Isuzu Midi",
        "Isuzu Minx",
        "Isuzu Fargo",
        "Isuzu MU",
        "Isuzu Panther",
        "Isuzu Rodeo",
        "Isuzu Statesman",
        "Isuzu Tiejingang",
        "Isuzu Trooper II",
        "Isuzu VehiCROSS",
      ],
      MORRISGARAGES: ["MG Hector", "MG ZS EV", "MG Gloster", "MG Hector Plus"],
    };

    return manufacturerModels[manufacturer] || [];
  };

  const handleNextButtonClick = () => {
    const form = document.querySelector("form");
    const a = validateStateVehicleNumber(carInsuranceData.Registration_No);
    console.log(a, "");
    if (a === false) {
      setInvalidRegiter(true);
    } else {
      if (form.checkValidity()) {
        // Proceed to the next page
        const serializedObj = encodeURIComponent(
          JSON.stringify(carInsuranceData)
        );
        // console.log(serializedObj)
        navigateTo(`/CarForm/CarForm2/${serializedObj}`);
      } else {
        // Show validation errors
        form.reportValidity();
      }
    }
  };

  const tableName = "car_insurance";
  const [newidd, setnewidd] = useState();

  useEffect(() => {
    const getnewid = async () => {
      const url = `https://policydekhoo-backend.onrender.com/Getnewid?tableName=${tableName}`;

      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch groups");
        }

        const data = await response.json();
        console.log("new id:", data);
        setnewidd(data);
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    getnewid();
  }, []);

  const { obj } = useParams();
  const obj1 = JSON.parse(decodeURIComponent(obj));
  console.log(obj1, "[][][]");

  const [carInsuranceData, setCarInsuranceData] = useState({
    id: "",
    Registration_No: obj1 === 1 ? "" : obj1.Registration_No,
    Manufacturer: obj1 === 1 ? "" : obj1.Manufacturer,
    Model: obj1 === 1 ? "" : obj1.Model,
    Fuel_Type: obj1 === 1 ? "" : obj1.Fuel_Type,
  });

  useEffect(() => {
    setCarInsuranceData((prevcarInsuranceData) => ({
      ...prevcarInsuranceData,
      id: newidd, // Update id with newidd
    }));
  }, [newidd]);

  useEffect(() => {
    console.log("New ID22:", newidd);
    console.log("New ID22=========:", carInsuranceData.id);
  }, [newidd, carInsuranceData.id]);

  const addValues = (e) => {
    setCarInsuranceData({
      ...carInsuranceData,
      [e.target.name]: e.target.value,
    });
  };

  const handlesubmit1 = async (e) => {
    e.preventDefault();

    console.log(carInsuranceData);
    try {
      const response = await fetch(
        "https://policydekhoo-backend.onrender.com/Setcarinsurancedata",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(carInsuranceData),
        }
      );

      if (response) {
        const responseData = await response.json();
        console.log("Data added successfully");
        console.log("New ID:", responseData.newid);
      } else {
        console.error("Failed to add data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    handleNextButtonClick();
  };

  const [language, setLanguage] = useState("en"); // 'en' for English, 'hi' for Hindi

  // Function to toggle between languages
  const toggleLanguage = () => {
    setLanguage((prevLang) => (prevLang === "en" ? "hi" : "en"));
  };

  return (
    <div className="">
      {toogle === false ? (
        <>
          <div className="container-fluid mb-5 ">
            <div className="row align-items-center">
              {/* Logo Section */}
              <div
                className="col-8 col-lg-3 mt-3 ps-5 d-flex align-items-center"
                onClick={() => {
                  navigateTo("/");
                }}
              >
                <img
                  src={log}
                  alt="Logo"
                  style={{ width: "12vw", maxWidth: "50px" }}
                />
                <span className="fs-5 ms-2">Policy Dekhoo</span>
              </div>

              {/* Navigation Links */}
              <div className="col-lg-7 col-4 d-flex justify-content-end align-items-center">
                <div className="row align-items-center w-100">
                  {/* Desktop Menu */}
                  <div className="col-lg-9 d-none d-lg-block">
                    <ul className="list-inline mb-0">
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigateTo("/");
                        }}
                      >
                        Home
                      </li>
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        PD Advantages
                      </li>
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Customer Rating
                      </li>
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigateTo("/About")}
                      >
                        About Us
                      </li>
                      <li className="list-inline-item dropdown p-1 fs-6">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ cursor: "pointer" }}
                        >
                          Support
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                          style={{ minWidth: "200px" }}
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              <img src={Callsupport} className="me-2" alt="" />
                              Call Us
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="mailto:info@policydekhoo.com"
                            >
                              <img
                                src={Mailsupport}
                                className="me-2"
                                alt="Mail Support"
                              />
                              Mail Us
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://wa.me/7734007799"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={Whatupsupport}
                                className="me-2"
                                alt="WhatsApp Support"
                              />
                              WhatsApp
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleOpen();
                              }}
                            >
                              <img
                                src={Callback}
                                className="me-2"
                                alt="Callback Icon"
                              />
                              Call Back
                            </a>
                          </li>

                          {/* Modal */}
                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              {/* Modal Content */}
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                              >
                                Request a Call Back
                              </Typography>
                              <Box
                                component="form"
                                sx={{
                                  mt: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 2,
                                }}
                              >
                                {/* Phone Number Input */}
                                <TextField
                                  id="phone-number"
                                  label="Phone Number"
                                  variant="outlined"
                                  fullWidth
                                  // onKeyPress={handleNumericOnly}
                                  maxLength={10}
                                />

                                {/* Reason Textarea */}
                                <TextField
                                  id="reason"
                                  label="Reason"
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  rows={4}
                                />

                                {/* Buttons */}
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    gap: 1,
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      // Add your submission logic here
                                      handleClose();
                                    }}
                                  >
                                    Request Call
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleClose}
                                  >
                                    Close
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </Modal>
                        </ul>
                      </li>
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigateTo("/Claim")}
                      >
                        Claim
                      </li>
                    </ul>
                  </div>

                  {/* Mobile Menu */}
                  <div className="col-lg-3 col-4 d-lg-none text-end">
                    <img
                      src={hambergar}
                      alt="Menu"
                      style={{
                        cursor: "pointer",
                        width: "30px",
                        height: "30px",
                      }}
                      onClick={() => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>

              {/* Contact Section */}
              <div className="col-lg-2 d-none d-lg-flex  justify-content-end pe-5">
                <span
                  className="fs-6 fw-bold "
                  style={{
                    cursor: "pointer",
                    borderBottom: "2px solid black", // Adjust thickness and color as needed
                    // paddingBottom: "4px",
                  }}
                >
                  Contact
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === true ? (
        <>
          <div className="container menuu">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-8  text-start ps-5">
                    <span className="fs-3">Policy dekhoo</span>
                  </div>
                  <div className="col-4">
                    <img
                      src={cross}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-7 col-1">
                <ul
                  className=""
                  style={{ listStyle: "none", textAlign: "left" }}
                >
                  <li
                    className=" p-2  fs-6"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigateTo("/")}
                  >
                    Home
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    PD Advantages
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Customer Rating
                  </li>
                  <li
                    className=" p-2 fs-6"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigateTo("/About")}
                  >
                    About Us
                  </li>
                  <li className="list-inline-item dropdown p-1 fs-6">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ cursor: "pointer" }}
                    >
                      Support
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                      style={{ minWidth: "200px" }}
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          <img src={Callsupport} className="me-2" alt="" />
                          Call Us
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="mailto:info@policydekhoo.com"
                        >
                          <img
                            src={Mailsupport}
                            className="me-2"
                            alt="Mail Support"
                          />
                          Mail Us
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="https://wa.me/7734007799"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={Whatupsupport}
                            className="me-2"
                            alt="WhatsApp Support"
                          />
                          WhatsApp
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleOpen();
                          }}
                        >
                          <img
                            src={Callback}
                            className="me-2"
                            alt="Callback Icon"
                          />
                          Call Back
                        </a>
                      </li>

                      {/* Modal */}
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          {/* Modal Content */}
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            Request a Call Back
                          </Typography>
                          <Box
                            component="form"
                            sx={{
                              mt: 2,
                              display: "flex",
                              flexDirection: "column",
                              gap: 2,
                            }}
                          >
                            {/* Phone Number Input */}
                            <TextField
                              id="phone-number"
                              label="Phone Number"
                              variant="outlined"
                              fullWidth
                              // onKeyPress={handleNumericOnly}
                              maxLength={10}
                            />

                            {/* Reason Textarea */}
                            <TextField
                              id="reason"
                              label="Reason"
                              variant="outlined"
                              fullWidth
                              multiline
                              rows={4}
                            />

                            {/* Buttons */}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                gap: 1,
                              }}
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  // Add your submission logic here
                                  handleClose();
                                }}
                              >
                                Request Call
                              </Button>
                              <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleClose}
                              >
                                Close
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Modal>
                    </ul>
                  </li>
                  <li
                    className="p-2 fs-6"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigateTo("/Claim")}
                  >
                    Claim
                  </li>
                </ul>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <span className="get p-2">Contact Us</span>
              </div>
            </div>

            <div className="row fixed-bottom">
              <div className="col-12">
                <div className=" ">
                  <span>© 2020 Policy Dekhoo | Powered by Policy Dekhoo</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {/* form */}
      {toogle === false ? (
        <>
          <div className="container-fluid">
            {/* Header Section */}
            <div className="row text-start">
              <div className="row">
                <div className="col-6 mx-4 ps-5">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => navigateTo("/")}
                  >
                    {/* {language === "en" ? "Home>" : "होम>"} */}
                    Home &#62; <span className="fw-bold">Car Insurance </span>
                  </span>
                  {/* <span className="fw-bold">{language === "en" ? "Car Insurance" : "गाड़ी बीमा>"}</span> */}
                </div>
                {/* <div className="col-4">
                  <img
                    src={trans}
                    style={{ width: "2vw", cursor: "pointer" }}
                    alt="Translate"
                    onClick={toggleLanguage}
                  />
                </div> */}
              </div>
            </div>

            {/* Main Content */}
            <div className="row">
              {/* Form Section */}
              <div className="col-lg-7">
                {/* Progress Bar */}
                <div className="row ps-4">
                  <div className="col-md-4 col-4">
                    <hr
                      className="w-100"
                      style={{ height: "2px", color: "#2D75E0" }}
                    />
                  </div>
                  <div className="col-md-4 col-4">
                    <hr className="w-100" style={{ height: "2px" }} />
                  </div>
                  <div className="col-md-4 col-4">
                    <hr className="w-100" style={{ height: "2px" }} />
                  </div>
                </div>

                {/* Headline */}
                <p className="fs-3 ps-5" style={{ textAlign: "left" }}>
                  {language === "en" ? (
                    <>
                      Get Your Car Insurance Quote Tailored to{" "}
                      <span className="fw-bold">your requirements.</span>
                    </>
                  ) : (
                    <>
                      अपनी आवश्यकताओं के अनुसार अपनी{" "}
                      <span className="fw-bold">
                        कार बीमा कोटेशन प्राप्त करें।
                      </span>
                    </>
                  )}
                </p>

                {/* Form */}
                <div className="container">
                  <form>
                    {/* Registration Number Field */}
                    <div className="row mt-5 mb-3 ps-md-5">
                      <div className="col-lg-6 text-start">
                        <label className="fw-bold ">
                          {language === "en"
                            ? "Registration No"
                            : "रजिस्ट्रेशन नंबर"}
                        </label>
                        <div className="mb-3">
                          <label htmlFor="" className="fw-bold">
                            रजिस्ट्रेशन नंबर
                          </label>
                        </div>
                        {/* <label className="mb-3">
                          {language === "en"
                            ? "Enter Vehicle Registration Number"
                            : "वाहन का रजिस्ट्रेशन नंबर डाले"}
                        </label> */}
                        <input
                          type="text"
                          className="form-control p-3"
                          placeholder={
                            language === "en"
                              ? "Enter Your Registration"
                              : "अपना पंजीकरण दर्ज करें"
                          }
                          pattern="[a-zA-Z0-9]*"
                          required
                          name="Registration_No"
                          style={{
                            border:
                              InvalidRegiter === true ? "2px solid red" : "",
                          }}
                          value={carInsuranceData.Registration_No}
                          onChange={(e) => addValues(e)}
                        />
                      </div>

                      {/* Manufacturer Field */}
                      <div className="col-lg-6 text-start">
                        <label className="fw-bold ">
                          {language === "en" ? "Manufacturer" : "वाहन की कंपनी"}
                        </label>
                        <div className="mb-3">
                          <label htmlFor="" className="fw-bold">
                            वाहन की कंपनी
                          </label>
                        </div>
                        {/* <label className="mb-3">
                          {language === "en"
                            ? "Select Vehicle Manufacturer"
                            : "वाहन की कंपनी चुने"}
                        </label> */}
                        <div>
                          <select
                            className="form-select p-3"
                            required
                            value={carInsuranceData.Manufacturer}
                            onChange={handleManufacturerChange}
                          >
                            <option value="">Enter Manufacturer Name</option>
                            <option value="Audi">Audi</option>
                            <option value="BMW">BMW</option>
                            <option value="CHEVROLET">CHEVROLET</option>
                            <option value="FIAT">FIAT</option>
                            <option value="FORD">FORD</option>
                            <option value="HONDA">HONDA</option>
                            <option value="HYUNDAI">HYUNDAI</option>
                            <option value="JAGUAR">JAGUAR</option>
                            <option value="VOLVO">VOLVO</option>
                            <option value="JEEP">JEEP</option>
                            <option value="LANDROVER">LAND ROVER</option>
                            <option value="KIA">KIA</option>
                            <option value="MAHINDRA">MAHINDRA</option>
                            <option value="MARUTI">MARUTI</option>
                            <option value="RENAULT">RENAULT</option>
                            <option value="SKODA">SKODA</option>
                            <option value="TATA">TATA</option>
                            <option value="TOYOTA">TOYOTA</option>
                            <option value="VOLKSWAGEN">VOLKSWAGEN</option>
                            <option value="MERCEDES">MERCEDES</option>
                            <option value="NISSAN">NISSAN</option>
                            <option value="ISUZU">ISUZU</option>
                            <option value="MORRIS GARAGES">
                              MORRIS GARAGES
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    {/* Model Field */}
                    <div className="row ps-md-5">
                      <div className="col-lg-6 text-start">
                        <label className="fw-bold ">
                          {language === "en" ? "Model" : "मॉडल"}
                        </label>
                        <div className="mb-3">
                          <label htmlFor="" className="fw-bold">
                            वाहन का मॉडल चुने
                          </label>
                        </div>
                        {/* <label className="mb-3">
                          {language === "en"
                            ? "Select Vehicle Model"
                            : "वाहन का मॉडल चुने"}
                        </label> */}
                        <select
                          className="form-select p-3"
                          required
                          name="Model"
                          value={carInsuranceData.Model}
                          onChange={(e) => addValues(e)}
                        >
                          <option value="">
                            {language === "en"
                              ? "Enter Model Name"
                              : "मॉडल का नाम दर्ज करें"}
                          </option>
                          {models.map((model) => (
                            <option key={model} value={model}>
                              {model}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* Fuel Type Field */}
                      <div className="col-lg-6 text-start">
                        <label className="fw-bold ">
                          {language === "en" ? "Fuel Type" : "ईंधन प्रकार"}
                        </label>
                        <div className="mb-3">
                          <label htmlFor="" className="fw-bold">
                            ईंधन प्रकार
                          </label>
                        </div>
                        {/* <label className="mb-3">
                          {language === "en"
                            ? "Select Fuel Type"
                            : "ईंधन का प्रकार चुनें"}
                        </label> */}
                        <select
                          className="form-select p-3"
                          required
                          name="Fuel_Type"
                          value={carInsuranceData.Fuel_Type}
                          onChange={(e) => addValues(e)}
                        >
                          <option value="">
                            {language === "en"
                              ? "Select Fuel Type"
                              : "ईंधन प्रकार का चयन करें"}
                          </option>
                          <option value="Petrol">Petrol</option>
                          <option value="Diesel">Diesel</option>
                          <option value="Electric">Electric</option>
                          <option value="Hybrid">Hybrid</option>
                        </select>
                      </div>
                    </div>

                    {/* Submit Button */}
                    <button
                      type="button"
                      className="p-2 but w-25 fs-5 mt-4"
                      onClick={(e) => {
                        handlesubmit1(e);
                        if (handleNextButtonClick()) {
                          navigateTo("/CarForm/CarForm2");
                        }
                      }}
                    >
                      {language === "en" ? "Next" : "अगला"}
                    </button>
                  </form>
                </div>
              </div>

              {/* Image Section */}
              <div className="col-lg-4 ps-5 d-none d-lg-block">
                <img src={carin} alt="Car" className="w-100" />
              </div>
            </div>
          </div>
          {/* HPI */}
          <div className="container-fluid mt-5">
            <div className="row  mx-5">
              <div className="col-lg-12  mb-5 text-start">
                <h2>Car Insurance Plan</h2>
              </div>
              <div className="row ">
                <div className="col-md-12 ">
                  <div className="text-start ">
                    <p className="fs-4">
                      Safeguard your vehicle and financial security with Policy
                      dekhoo's robust car insurance plans. Our comprehensive
                      coverage shields you against a myriad of risks, including
                      accidents, theft, fire, natural disasters, and third-party
                      liabilities. Customise your plan with versatile add-ons
                      like roadside assistance, ensuring tailored protection for
                      your specific needs.
                    </p>
                  </div>

                  <div className="text-start ">
                    <p className="fs-4">
                      In the event of an unfortunate incident, our streamlined
                      claims process ensures minimal disruption to your routine.
                      With Policy dekhoo, you can navigate the roads with
                      confidence, knowing that you're backed by a reliable
                      insurance provider committed to your peace of mind. Trust
                      us to protect your journey.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 
      <div className="container-fluid">
        <div className="row px-5 mb-5">
          <div className="col-md-12">
            <h5 style={{ textAlign: "justify" }} className="fs-1 ">
              FAQ
            </h5>
          </div>
        </div> */}
          {/* <div className="row px-5 ">
          <div className="col-lg-8 ">
            <div className="row ">
              <div className="col-md-12">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Why do I need insurance?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How is insurance premium calculated?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What is a waiting period in insurance?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      How do I file an insurance claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Can I customize my insurance coverage?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>

          <div className="col-4  d-none d-lg-block">
            <div>
              <img src={question} alt="" />
            </div>
          </div>
        </div>
      </div> */}

          {/* Footer */}

          <footer className="container-fluid backgroundtheme mt-5 ">
            <div className="row p-5">
              {/* Logo Section */}
              <div className="col-md-7 text-center text-md-start mb-4 mb-md-0">
                <div
                  className="mb-3 "
                  onClick={() => {
                    navigateTo("/");
                  }}
                >
                  <img
                    src={Footerlogo}
                    alt="Footer Logo"
                    style={{ maxWidth: "8vw" }}
                    className="footerlog"
                  />
                </div>
                <div className=" justify-content-center align-items-center w-50 d-none d-sm-block">
                  <span className="text-white">
                    Trust us for peace of mind and reliable protection, backed
                    by our unwavering commitment to your satisfaction and
                    security.
                  </span>
                </div>
                <div className=" justify-content-center align-items-center d-block d-sm-none">
                  <span className="text-white">
                    Trust us for peace of mind and reliable protection, backed
                    by our unwavering commitment to your satisfaction and
                    security.
                  </span>
                </div>
              </div>

              {/* Navigation Links */}
              <div className="col-md-5 mt-4	d-none d-sm-block">
                <div className="row">
                  {/* First Column */}
                  <div className="col-sm-6 text-center text-sm-start mb-4 mb-sm-0">
                    <ul className="list-unstyled ">
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        Home
                      </li>
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        PD Advantages
                      </li>
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        Customer Rating
                      </li>
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        About Us
                      </li>
                    </ul>
                  </div>

                  {/* Second Column */}
                  <div className="col-sm-6 text-center text-sm-start">
                    <ul className="list-unstyled ">
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        Support
                      </li>
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        Claim
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </footer>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h5 className="fs-6">
                  All Rights Reserved | Developed By{" "}
                  <a
                    href="https://www.unairesync.com/"
                    className="link"
                    target="blank"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Unaire Sync
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default CarForm;
