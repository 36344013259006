import React from "react";
import { useState, useEffect,useRef } from "react";
// import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import "./LifeInsuranceform.css";
import life from "../resources/doctor.svg";
// import questi from "../resources/doctorques.svg";
// import Accordion from "react-bootstrap/Accordion";
import logo from "../resources/Logo.svg";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import  {AdapterDayjs}  from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import male1 from "../resources/male.svg";
import male2 from "../resources/male-modified.png";
import female1 from "../resources/Fem.svg";
import female2 from "../resources/Fem-modified.png";
import hambergar from "../resources/hambarger.svg";
import cross from "../resources/cross.svg";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Footerlogo from "../resources/pollicydekhoo-Cdr-jpg-300x300 1 (1).png"
import Callsupport from "../resources/callsupport.svg";
import Callback from "../resources/callbacksupport.svg";
import Whatupsupport from "../resources/whatupsupport.svg";
import Mailsupport from "../resources/mailsupport.svg";
import { Modal, Box, Typography, Button } from "@mui/material";


function LifeInsuranceform() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };

  const contactFormRef = useRef(null);

  // Function to scroll to the contact form
  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    console.log("Scrolling to the top");
    window.scrollTo(0, 0);
  }, []);
  
  const handleNextButtonClick = () => {
    const form = document.querySelector("form");
    if (form.checkValidity()) {
      // Proceed to the next page
      const serializedObj = encodeURIComponent(JSON.stringify(TermLife));
      navigateTo(`/Lifeinsuranceform/LifeInsuranceform2/${serializedObj}`);
    } else {
      // Show validation errors
      form.reportValidity();
    }
  };

  const [toogle, settoggle] = useState(false);

  const [selectYes, setselectYes] = useState(null);
  // const [currentImage, setCurrentImage] = useState(female2);
  // const [changesImage, setchnagesImage] = useState(male2);

  const handleRadioClick = (value) => {
    if (value === selectYes) {
      setselectYes(null);
      setTermLife({
        ...TermLife,
        tobacco_Smoke: value,
      });
    } else {
      setTermLife({
        ...TermLife,
        tobacco_Smoke: value,
      });
      setselectYes(value);
    }
  };

  // const handleClick = () => {
  //   setCurrentImage(currentImage === female1 ? female2 : female1);
  // };

  // const handlClick = () => {
  //   setchnagesImage(changesImage === male1 ? male2 : male1);
  // };

  const [currentImage, setCurrentImage] = useState(male2);
  const [changesImage, setChangesImage] = useState(female2);

  const handleClick = () => {
    setCurrentImage(male1);
    setChangesImage(female2);
    setTermLife({ ...TermLife, Gender: "male" });
  };

  const handlClick = () => {
    setChangesImage(female1);
    setCurrentImage(male2);
    setTermLife({ ...TermLife, Gender: "female" });
  };

  const ages = Array.from({ length: 77 }, (_, i) => 23 + i);

  const amounts = [
    "25 Lakh",
    "30 Lakh",
    "35 Lakh",
    "40 Lakh",
    "45 Lakh",
    "50 Lakh",
    "55 Lakh",
    "60 Lakh",
    "65 Lakh",
    "70 Lakh",
    "75 Lakh",
    "80 Lakh",
    "85 Lakh",
    "90 Lakh",
    "95 Lakh",
    "1.00 Cr",
    "1.25 Cr",
    "1.50 Cr",
    "1.75 Cr",
    "2.00 Cr",
    "2.25 Cr",
    "2.50 Cr",
    "2.75 Cr",
    "3.00 Cr",
    "3.25 Cr",
    "3.50 Cr",
    "3.75 Cr",
    "4.00 Cr",
    "4.25 Cr",
    "4.50 Cr",
    "4.75 Cr",
    "5.00 Cr",
    "Other",
  ];
  const [pincode, setPincode] = useState("");
  // console.log(pincode, "-=-=-=-==");
  const handleNumericOnly = (event) => {
    const { value } = event.target;

    const isnot = /^[0-9]*$/.test(event.key);
    const isnot2 = /^$/.test(event.key);
    if (isnot || isnot2) {
      setPincode(value);
    } else {
      event.preventDefault();
    }
  };

  const tableName = "termlife_insurance";
  const [newidd, setnewidd] = useState();

  useEffect(() => {
    const getnewid = async () => {
      const url = `https://policydekhoo-backend.onrender.com/Getnewid?tableName=${tableName}`;

      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch groups");
        }

        const data = await response.json();
        console.log("new id:", data);
        setnewidd(data);
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    getnewid();
  }, []);

  const [TermLife, setTermLife] = useState({
    id: "",
    Gender: "",
    Cover_Value: "",
    Date_of_Birth: "",
    Annual_Income: "",
    Coverage_Till_Age: "",
    tobacco_Smoke: "",
    Occupation_Type: "",
    Education: "",
  });
  useEffect(() => {
    setTermLife((prevTermLife) => ({
      ...prevTermLife,
      id: newidd, // Update id with newidd
    }));
  }, [newidd]);

  useEffect(() => {
    console.log("New ID22:", newidd);
    console.log("New ID22=========:", TermLife.id);
  }, [newidd, TermLife.id]);

  const addValues = (e) => {
    setTermLife({ ...TermLife, [e.target.name]: e.target.value });
  };
  console.log(TermLife);

  const handlesubmit1 = async (e) => {
    e.preventDefault();

    console.log(TermLife);
    try {
      const response = await fetch(
        "https://policydekhoo-backend.onrender.com/Settermlifeinsurancedata",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(TermLife),
        }
      );

      if (response) {
        const responseData = await response.json();
        console.log("Data added successfully");
        console.log("New ID:", responseData.newid);
      } else {
        console.error("Failed to add data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    handleNextButtonClick();
  };
  return (
    <div>
      {toogle === false ? (
        <>
          <div className="container-fluid mb-5 ">
            <div className="row align-items-center">
              {/* Logo Section */}
              <div className="col-8 col-lg-3 mt-3 ps-5 d-flex align-items-center" onClick={()=> navigate("/")}>
                <img
                  src={logo}
                  alt="Logo"
                  style={{ width: "12vw", maxWidth: "50px" }}
                />
                <span className="fs-5 ms-2">Policy Dekhoo</span>
              </div>

              {/* Navigation Links */}
              <div className="col-lg-7 col-4 d-flex justify-content-end align-items-center">
                <div className="row align-items-center w-100">
                  {/* Desktop Menu */}
                  <div className="col-lg-9 d-none d-lg-block">
                    <ul className="list-inline mb-0">
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={()=>navigate("/")}
                      >
                        Home
                      </li>
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        PD Advantages
                      </li>
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Customer Rating
                      </li>
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigateTo("/About")}
                      >
                        About Us
                      </li>
                     <li className="list-inline-item dropdown p-1 fs-6">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ cursor: "pointer" }}
                        >
                          Support
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                          style={{ minWidth: "200px" }}
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              <img src={Callsupport} className="me-2" alt="" />
                              Call Us
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="mailto:info@policydekhoo.com"
                            >
                              <img
                                src={Mailsupport}
                                className="me-2"
                                alt="Mail Support"
                              />
                              Mail Us
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://wa.me/7734007799"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={Whatupsupport}
                                className="me-2"
                                alt="WhatsApp Support"
                              />
                              WhatsApp
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleOpen();
                              }}
                            >
                              <img
                                src={Callback}
                                className="me-2"
                                alt="Callback Icon"
                              />
                              Call Back
                            </a>
                          </li>

                          {/* Modal */}
                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              {/* Modal Content */}
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                              >
                                Request a Call Back
                              </Typography>
                              <Box
                                component="form"
                                sx={{
                                  mt: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 2,
                                }}
                              >
                                {/* Phone Number Input */}
                                <TextField
                                  id="phone-number"
                                  label="Phone Number"
                                  variant="outlined"
                                  fullWidth
                                  // onKeyPress={handleNumericOnly}
                                  maxLength={10}
                                />

                                {/* Reason Textarea */}
                                <TextField
                                  id="reason"
                                  label="Reason"
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  rows={4}
                                />

                                {/* Buttons */}
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    gap: 1,
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      // Add your submission logic here
                                      handleClose();
                                    }}
                                  >
                                    Request Call
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleClose}
                                  >
                                    Close
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </Modal>
                        </ul>
                      </li>
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigateTo("/Claim")}
                      >
                        Claim
                      </li>
                    </ul>
                  </div>

                  {/* Mobile Menu */}
                  <div className="col-lg-3 col-4 d-lg-none text-end">
                    <img
                      src={hambergar}
                      alt="Menu"
                      style={{
                        cursor: "pointer",
                        width: "30px",
                        height: "30px",
                      }}
                      onClick={() => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>

              {/* Contact Section */}
              <div className="col-lg-2 d-none d-lg-flex  justify-content-end pe-5">
                <span
                  className="fs-6 fw-bold "
                  style={{
                    cursor: "pointer",
                    borderBottom: "2px solid black", // Adjust thickness and color as needed
                    // paddingBottom: "4px",
                  }}
                >
                  Contact
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === true ? (
        <>
          <div className="container menuu">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-8  text-start ps-5">
                    <span className="fs-3">Policy dekhoo</span>
                  </div>
                  <div className="col-4">
                    <img
                      src={cross}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-7 col-1">
                <ul
                  className=""
                  style={{ listStyle: "none", textAlign: "left" }}
                >
                  <li
                    className=" p-2  fs-6"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigateTo("/");
                    }}
                  >
                    Home
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    PD Advantages
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Customer Rating
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}  onClick={() => navigateTo("/About")}>
                    About Us
                  </li>
                  <li className="list-inline-item dropdown p-1 fs-6">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ cursor: "pointer" }}
                        >
                          Support
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                          style={{ minWidth: "200px" }}
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              <img src={Callsupport} className="me-2" alt="" />
                              Call Us
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="mailto:info@policydekhoo.com"
                            >
                              <img
                                src={Mailsupport}
                                className="me-2"
                                alt="Mail Support"
                              />
                              Mail Us
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://wa.me/7734007799"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={Whatupsupport}
                                className="me-2"
                                alt="WhatsApp Support"
                              />
                              WhatsApp
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleOpen();
                              }}
                            >
                              <img
                                src={Callback}
                                className="me-2"
                                alt="Callback Icon"
                              />
                              Call Back
                            </a>
                          </li>

                          {/* Modal */}
                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              {/* Modal Content */}
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                              >
                                Request a Call Back
                              </Typography>
                              <Box
                                component="form"
                                sx={{
                                  mt: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 2,
                                }}
                              >
                                {/* Phone Number Input */}
                                <TextField
                                  id="phone-number"
                                  label="Phone Number"
                                  variant="outlined"
                                  fullWidth
                                  // onKeyPress={handleNumericOnly}
                                  maxLength={10}
                                />

                                {/* Reason Textarea */}
                                <TextField
                                  id="reason"
                                  label="Reason"
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  rows={4}
                                />

                                {/* Buttons */}
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    gap: 1,
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      // Add your submission logic here
                                      handleClose();
                                    }}
                                  >
                                    Request Call
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleClose}
                                  >
                                    Close
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </Modal>
                        </ul>
                      </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}  onClick={() => navigateTo("/Claim")}>
                    Claim
                  </li>
                </ul>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <span className="get p-2" onClick={scrollToContactForm}>Contact Us</span>
              </div>
            </div>

            <div className="row fixed-bottom">
              <div className="col-12">
                <div className=" ">
                  <span>© 2020 Policy Dekhoo | Powered by Policy Dekhoo</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {toogle === false ? (
        <>
          <div className="container-fluid">
            <div className="row mt-4 ">
              <div className="col-sm-10 ps-5 ">
                <div className="text-start  mx-5">
                  <span
                    className="fw-lighter"
                    onClick={() => {
                      navigateTo("/");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Home&#62;
                  </span>
                  <span style={{ fontWeight: "600" }}>Term Life</span>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-sm-6 mt-5 d-none d-lg-block">
                <div className="">
                  <img src={life} alt="" />
                </div>
              </div>
              <div className="col-lg-5  ps-5">
                <div className="heade">
                  <div className="w-75">
                    <p className="fs-2" style={{ textAlign: "left" }}>
                      Discover the Right Term Life Insurance Policy for{" "}
                      <span className="fw-bold">your requirements.</span>
                    </p>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-md-6">
                    <div className="">
                      <button
                        className="w-75 male p-2"
                        style={{ border: "none", background: "#fff" }}
                        onClick={handleClick}
                      >
                        <img src={currentImage} alt="" className="" />
                        Male
                      </button>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="">
                      <button
                        className="w-75 female p-2 "
                        style={{ border: "none", background: "#fff" }}
                        onClick={handlClick}
                      >
                        <img src={changesImage} alt="" className="" />
                        Female
                      </button>
                    </div>
                  </div>
                </div>
                <form action="">
                  <div className="row">
                    <div className="col-md-6 mt-4">
                      <div className="text-start">
                        <label htmlFor="" className="fw-bold">
                          Cover Value
                          <div>
                            <label htmlFor="" className="from-label fw-bold">
                            कितना कवर चाहिए
                            </label>
                          </div>
                        </label>
                        {/* <input
                          type="text"
                          className="form-control p-3 mt-2"
                          id=""
                          placeholder="Enter Your Cover Value"
                          required
                        /> */}
                        <select
                          id=""
                          className="form-select p-3"
                          name="Cover_Value"
                          value={TermLife.Cover_Value}
                          required
                          onChange={(e) => {
                            addValues(e);
                          }}
                        >
                          <option value="">Select Your Cover Value</option>
                          {amounts.map((amount) => (
                            <option key={amount} value={amount}>
                              {amount}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-6 mt-4">
                      <div className="text-start">
                        <label htmlFor="" className="fw-bold ">
                          Date of Birth
                          <div>
                            <label htmlFor="" className="from-label fw-bold">
                            जन्म तिथि
                            </label>
                          </div>
                        </label>
                        <div>
                          <TextField
                            id="outlined-basic"
                            type="date"
                            size="medium"
                            fullWidth
                            variant="outlined"
                            name="Date_of_Birth"
                            value={TermLife.Date_of_Birth}
                            onChange={(e) => {
                              addValues(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="text-start">
                        <label htmlFor="" className="fw-bold mt-4">
                          Annual Income
                          <div>
                            <label htmlFor="" className="from-label fw-bold">
                            वार्षिक आय चुनें
                            </label>
                          </div>
                        </label>
                        <select  id="" className="form-select p-3"  name="Annual_Income"
                          value={TermLife.Annual_Income}
                          onChange={(e) => {
                            addValues(e);
                          }}>
                            <option value="">Annuanl Income</option>
                          <option value="25 Lac +">25 Lac +</option>
                          <option value="15 Lac to 24.9 Lac">15 Lac to 24.9 Lac</option>
                          <option value="10 Lac to 14.9 Lac">10 Lac to 14.9 Lac</option>
                          <option value="8 Lac to 9.9 Lac">8 Lac to 9.9 Lac</option>
                          <option value="5 Lac to 7.9 Lac">5 Lac to 7.9 Lac</option>
                          <option value="3 Lac to 4.9 Lac">3 Lac to 4.9 Lac</option>
                          <option value="2 Lac to 2.9">2 Lac to 2.9</option>
                          <option value="Less than 2 Lac">Less than 2 Lac</option>
                        </select>
                        {/* <input
                          type="text"
                          className="form-control p-3 "
                          id=""
                          placeholder="Enter Your Annual Income"
                          required
                          maxLength={"8"}
                          onKeyPress={handleNumericOnly}
                          name="Annual_Income"
                          value={TermLife.Annual_Income}
                          onChange={(e) => {
                            addValues(e);
                          }}
                        /> */}
                      </div>
                    </div>

                    <div className="col-md-6  text-start">
                      <div>
                        <label htmlFor="" className="fw-bold mt-4">
                          Coverage Till Age
                          <div>
                            <label htmlFor="" className="from-label fw-bold">
                            किस उम्र तक कवरेज चाहिए
                            </label>
                          </div>
                        </label>
                        <select
                          id="loanAmountSelect"
                          className="p-3 form-select"
                          name="Coverage_Till_Age"
                          value={TermLife.Coverage_Till_Age}
                          onChange={(e) => {
                            addValues(e);
                          }}
                        >
                          <option value="">Select Coverage Till Age</option>
                          {ages.map((age) => (
                            <option key={age} value={age}>
                              {age}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 ">
                      <div className="text-start mt-4">
                        <span className="fw-bold ">Chew tobacco or Smoke
                        <div>
                            <label htmlFor="" className="from-label fw-bold">
                            तम्बाकू चबाना या धूम्रपान करना
                            </label>
                          </div>
                        </span>
                      </div>
                    </div>

                    <div className="col-md-6  text-md-start">
                      <div>
                        <div className=" mt-3">
                          <div>
                            <input
                              type="radio"
                              id=""
                              className="mt-4"
                              checked={selectYes === "Yes" ? "true" : null}
                              onClick={() => handleRadioClick("Yes")}
                              required
                              //   name="tobacco_Smoke"
                              // value={TermLife.tobacco_Smoke}
                              // onChange={(e) => {
                              //   addValues(e);
                              // }}
                            />
                            <label for="" className="me-5 ps-3">
                              Yes
                              <div>
                            <label htmlFor="" className="from-label fw-bold">
                            हां 
                            </label>
                          </div>
                            </label>

                            <input
                              type="radio"
                              id=""
                              checked={selectYes === "No" ? "true" : null}
                              onClick={() => handleRadioClick("No")}
                              required
                              // name="tobacco_Smoke"
                              // value={TermLife.tobacco_Smoke}
                              // onChange={(e) => {
                              //   addValues(e);
                              // }}
                            />
                            <label for="" className="ps-3">
                              No <div>
                            <label htmlFor="" className="from-label fw-bold">
                            नहीं
                            </label>
                          </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="text-start">
                        <label htmlFor="" className="fw-bold mt-4">
                          Your Occupation Type
                          <div>
                            <label htmlFor="" className="from-label fw-bold">
                            आपका व्यवसाय प्रकार
                            </label>
                          </div>
                        </label>
                        <select
                          className="form-select  p-3"
                          required
                          name="Occupation_Type"
                          value={TermLife.Occupation_Type}
                          onChange={(e) => {
                            addValues(e);
                          }}
                        >
                          <option value="">Enter Your Occupation Type</option>
                          <option value="Salaried">Salaried</option>
                          <option value="Self Emplyed">Self Emplyed</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="text-start">
                        <label htmlFor="" className="fw-bold mt-4">
                          Education
                          <div>
                            <label htmlFor="" className="from-label fw-bold">
                            शिक्षा
                            </label>
                          </div>
                        </label>
                        <select
                          className="form-select  p-3"
                          required
                          name="Education"
                          value={TermLife.Education}
                          onChange={(e) => {
                            addValues(e);
                          }}
                        >
                          <option value="">Select Education</option>
                          <option value="Post Graduate">Post Graduate</option>
                          <option value="Graduate">Graduate</option>
                          <option value="12th Pass">12th Pass</option>
                          <option value="10th Pass">10th Pass</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-md-12">
                      <div className="text-start">
                        <label htmlFor="" className="fw-bold mt-4">
                          Your Annual income
                        </label>
                        <input
                          type="text"
                          className="form-control p-3 "
                          name=""
                          id=""
                          placeholder="Enter Your Annual Income"
                          required
                        />
                      </div>
                    </div>
                  </div> */}
                  <div>
                    <button
                      className="mt-5 p-3 but4"
                      onClick={(e) => {
                        handlesubmit1(e);
                        if (handleNextButtonClick()) {
                          navigateTo("/LifeInsuranceform/LifeInsuranceform2");
                        }
                      }}
                    >
                      Procced
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row  mx-5">
              <div className="col-lg-12  text-start p-4">
                <h2>Term Life Insurance Plan</h2>
              </div>
              <div className="row ">
                <div className="col-md-12 ">
                  <div className="text-start ">
                    <p className="fs-4">
                      Ensure the financial security of your loved ones with
                      Policy dekhoo's term life insurance plans. Our policies
                      offer a lump sum payout upon the policyholder's demise,
                      ensuring that your family's financial needs are met.
                      Choose from a variety of coverage options and premium
                      payment terms tailored to your budget and requirements.
                    </p>
                  </div>

                  <div className="text-start ">
                    <p className="fs-4">
                      With Policy Dekhoo , rest assured that your family's
                      future is safeguarded, regardless of the circumstances.
                      Our commitment to providing reliable protection ensures
                      that you can trust us to support your loved ones when they
                      need it most. Secure peace of mind with Policy dekhoo's
                      term life insurance, knowing that your family's financial
                      well-being is in good hands.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="container-fluid">
              <div className="row px-5 mb-5 ps-3">
                <div className="col-md-12">
                  <h5 style={{ textAlign: "justify" }} className="fs-1 ">
                    FAQ
                  </h5>
                </div>
              </div>
              <div className="row px-5 ps-3">
                <div className="col-lg-8 ">
                  <div className="row ">
                    <div className="col-md-12">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            Why do I need insurance?
                          </Accordion.Header>
                          <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            How is insurance premium calculated?
                          </Accordion.Header>
                          <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                          <Accordion.Header>
                            What is a waiting period in insurance?
                          </Accordion.Header>
                          <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                          <Accordion.Header>
                            How do I file an insurance claim?
                          </Accordion.Header>
                          <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                          <Accordion.Header>
                            Can I customize my insurance coverage?
                          </Accordion.Header>
                          <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>

                <div className="col-sm-4 d-none d-lg-block">
                  <div>
                    <img src={questi} alt="" />
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          <footer className="container-fluid backgroundtheme mt-5 ">
            <div className="row p-5">
              {/* Logo Section */}
              <div className="col-md-7 text-center text-md-start mb-4 mb-md-0">
                <div className="mb-3">
                  <img
                    src={Footerlogo}
                    alt="Footer Logo"
                    style={{ maxWidth: "8vw" }}
                    className="footerlog"
                  />
                </div>
                <div className=" justify-content-center align-items-center w-50 d-none d-sm-block">
                  <span className="text-white">
                    Trust us for peace of mind and reliable protection, backed
                    by our unwavering commitment to your satisfaction and
                    security.
                  </span>
                </div>
                <div className=" justify-content-center align-items-center d-block d-sm-none">
                  <span className="text-white">
                    Trust us for peace of mind and reliable protection, backed
                    by our unwavering commitment to your satisfaction and
                    security.
                  </span>
                </div>
              </div>

              {/* Navigation Links */}
              <div className="col-md-5 mt-4	d-none d-sm-block">
                <div className="row">
                  {/* First Column */}
                  <div className="col-sm-6 text-center text-sm-start mb-4 mb-sm-0">
                    <ul className="list-unstyled ">
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                         onClick={() => navigateTo("/")}
                      >
                        Home
                      </li>
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        PD Advantages
                      </li>
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        Customer Rating
                      </li>
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                        onClick={() => navigateTo("/About")}
                      >
                        About Us
                      </li>
                    </ul>
                  </div>

                  {/* Second Column */}
                  <div className="col-sm-6 text-center text-sm-start">
                    <ul className="list-unstyled ">
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        Support
                      </li>
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                        onClick={() => navigateTo("/Claim")}
                      >
                        Claim
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </footer>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h5 className="fs-6">
                All Rights Reserved | Developed By {" "}
                  <a
                    href="https://www.unairesync.com/"
                    className="link"
                    target="blank"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Unaire Sync
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default LifeInsuranceform;
