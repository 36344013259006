import React from "react";
import { useState, useEffect, useRef } from "react";
import "./Personal.css";
// import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import doc from "../resources/personaldcoc.svg";
// import { Accordion } from "react-bootstrap";
import logo from "../resources/Logo.svg";
// import docques from "../resources/docquestion.svg";
import male1 from "../resources/male.svg";
import male2 from "../resources/male-modified.png";
import female1 from "../resources/Fem.svg";
import female2 from "../resources/Fem-modified.png";
import hambergar from "../resources/hambarger.svg";
import cross from "../resources/cross.svg";
import { useNavigate } from "react-router-dom";
import PersonalForm2 from "./PersonalForm2";
import Footerlogo from "../resources/pollicydekhoo-Cdr-jpg-300x300 1 (1).png";
import Callsupport from "../resources/callsupport.svg";
import Callback from "../resources/callbacksupport.svg";
import Whatupsupport from "../resources/whatupsupport.svg";
import Mailsupport from "../resources/mailsupport.svg";
import { Modal, Box, Typography, Button, TextField } from "@mui/material";

function Personalloneform() {
  // const[gender,setGender]=useState("male");
  // console.log(gender);
  // const proceedform = ()=> {
  //     console.log("ooooooooooo")
  //     // sethealthform2(true)
  // }
  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const contactFormRef = useRef(null);

  // Function to scroll to the contact form
  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    console.log("Scrolling to the top");
    window.scrollTo(0, 0);
  }, []);

  const [gender, setGender] = useState("male");
  console.log(gender);
  const proceedform = () => {
    // setShowPersonalForm2(true)
    console.log("ooooooooooo");
    let a = { ...PersonalAccident };
    console.log(a);
    if (PersonalAccident.Spouse_Occupation === "") {
      a.Spouse_Occupation = null;
    }
    console.log(a);
    setPersonalAccident(a);
    // const serializedObj = encodeURIComponent(JSON.stringify(a));
    // navigateTo(`/PersonalLoanform/PersonalForm2/${serializedObj}`);
    setShowPersonalForm2(true);
  };

  const handleGenderChange = (newGender) => {
    setGender(newGender);
    console.log(gender);
    setPersonalAccident({ ...PersonalAccident, Self_Gender: newGender });
    // Add your logic here to update the name based on the selected gender
  };

  const [toogle, settoggle] = useState(false);
  const [showSpouseOccupation, setShowSpouseOccupation] = useState(false);

  const [bold, setbold] = useState();
  const [currentImage, setCurrentImage] = useState(female2);
  const [changesImage, setchnagesImage] = useState(male2);
  const [familyArr, setFamilyArr] = useState([]);
  const [showPersonalForm2, setShowPersonalForm2] = useState(false);

  const handleNextButtonClick = () => {
    const form = document.querySelector("form");
    if (form.checkValidity()) {
      // navigateTo("/PersonalLoanform/PersonalForm2");
    } else {
      form.reportValidity();
    }
  };

  const handleFemaleClick = () => {
    setCurrentImage(currentImage === female1 ? female2 : female1);
    if (currentImage === female2) {
      let inplist = [...familyArr];
      if (inplist.includes("female") === false) {
        inplist.push("female");
        setFamilyArr(inplist);
      } else {
        inplist = inplist.filter((item) => item !== "female");
        setFamilyArr(inplist);
      }
      // setGender("female");
    }

    if (gender === "male") {
      console.log("hiiiiiiii");
      setShowSpouseOccupation(!showSpouseOccupation);
    }
  };
  console.log(familyArr);
  const handleMaleClick = () => {
    console.log("callledddddd");
    setchnagesImage(changesImage === male1 ? male2 : male1);
    if (changesImage === male2) {
      let inplist = [...familyArr];
      if (inplist.includes("male") === false) {
        inplist.push("male");
        setFamilyArr(inplist);
      } else {
        inplist = inplist.filter((item) => item !== "male");
        setFamilyArr(inplist);
      }
    }
    if (gender === "female") {
      setShowSpouseOccupation(!showSpouseOccupation);
    }
  };
  console.log(showSpouseOccupation, "spouseeeeeeeee");

  const [pincode, setPincode] = useState("");
  console.log(pincode, "-=-=-=-==");
  const handleNumericOnly = (event) => {
    const { value } = event.target;

    const isnot = /^[0-9]*$/.test(event.key);
    const isnot2 = /^$/.test(event.key);
    if (isnot || isnot2) {
      setPincode(value);
    } else {
      event.preventDefault();
    }
  };

  const [pincod, setPincod] = useState("");
  const [pincodError, setPincodError] = useState("");

  const handleNumeric = (event) => {
    const { value } = event.target;
    const isValid = /^[0-9]*$/.test(event.key) || /^$/.test(event.key);

    if (isValid) {
      setPincod(value);
      setPincodError(""); // Reset error message
    } else {
      event.preventDefault();
      setPincodError("Pincode should contain only numbers.");
    }

    if (value.length !== 6) {
      setPincodError("Pincode should be 6 digits long.");
    } else {
      setPincodError("");
    }
  };

  const tableName = "personal_accident";
  const [newidd, setnewidd] = useState();

  useEffect(() => {
    const getnewid = async () => {
      const url = `https://policydekhoo-backend.onrender.com/Getnewid?tableName=${tableName}`;

      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch groups");
        }

        const data = await response.json();
        console.log("new id:", data);
        setnewidd(data);
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    getnewid();
  }, []);

  const [PersonalAccident, setPersonalAccident] = useState({
    id: "",
    Self_Gender: "male",
    Self_Occupation: "",
    Spouse_Occupation: null,
    Annual_Income: "",
    Pincode: "",
  });

  useEffect(() => {
    setPersonalAccident((prevPersonalAccident) => ({
      ...prevPersonalAccident,
      id: newidd, // Update id with newidd
    }));
  }, [newidd]);

  useEffect(() => {
    console.log("New ID22:", newidd);
    console.log("New ID22=========:", PersonalAccident.id);
  }, [newidd, PersonalAccident.id]);

  const addValues = (e) => {
    setPersonalAccident({
      ...PersonalAccident,
      [e.target.name]: e.target.value,
    });
  };
  console.log(familyArr, "familllyuuuy");

  const handlesubmit1 = async (e) => {
    e.preventDefault();

    console.log(PersonalAccident);
    try {
      const response = await fetch(
        "https://policydekhoo-backend.onrender.com/Setpersonalaccidentdata",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(PersonalAccident),
        }
      );

      if (response) {
        const responseData = await response.json();
        console.log("Data added successfully");
        console.log("New ID:", responseData.newid);
      } else {
        console.error("Failed to add data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    handleNextButtonClick();
  };

  return (
    <>
      {showPersonalForm2 ? (
        <PersonalForm2 familyArr={familyArr} obj1={PersonalAccident} />
      ) : (
        <>
          <div>
            {toogle === false ? (
              <>
                <div className="container-fluid mb-5 ">
                  <div className="row align-items-center">
                    {/* Logo Section */}
                    <div
                      className="col-8 col-lg-3 mt-3 ps-5 d-flex align-items-center"
                      onClick={() => {
                        navigateTo("/");
                      }}
                    >
                      <img
                        src={logo}
                        alt="Logo"
                        style={{ width: "12vw", maxWidth: "50px" }}
                      />
                      <span className="fs-5 ms-2">Policy Dekhoo</span>
                    </div>

                    {/* Navigation Links */}
                    <div className="col-lg-7 col-4 d-flex justify-content-end align-items-center">
                      <div className="row align-items-center w-100">
                        {/* Desktop Menu */}
                        <div className="col-lg-9 d-none d-lg-block">
                          <ul className="list-inline mb-0">
                            <li
                              className="list-inline-item p-1 fs-6"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigateTo("/");
                              }}
                            >
                              Home
                            </li>
                            <li
                              className="list-inline-item p-1 fs-6"
                              style={{ cursor: "pointer" }}
                            >
                              PD Advantages
                            </li>
                            <li
                              className="list-inline-item p-1 fs-6"
                              style={{ cursor: "pointer" }}
                            >
                              Customer Rating
                            </li>
                            <li
                              className="list-inline-item p-1 fs-6"
                              style={{ cursor: "pointer" }}
                              onClick={() => navigateTo("/About")}
                            >
                              About Us
                            </li>
                            <li className="list-inline-item dropdown p-1 fs-6">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ cursor: "pointer" }}
                        >
                          Support
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                          style={{ minWidth: "200px" }}
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              <img src={Callsupport} className="me-2" alt="" />
                              Call Us
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="mailto:info@policydekhoo.com"
                            >
                              <img
                                src={Mailsupport}
                                className="me-2"
                                alt="Mail Support"
                              />
                              Mail Us
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://wa.me/7734007799"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={Whatupsupport}
                                className="me-2"
                                alt="WhatsApp Support"
                              />
                              WhatsApp
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleOpen();
                              }}
                            >
                              <img
                                src={Callback}
                                className="me-2"
                                alt="Callback Icon"
                              />
                              Call Back
                            </a>
                          </li>

                          {/* Modal */}
                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              {/* Modal Content */}
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                              >
                                Request a Call Back
                              </Typography>
                              <Box
                                component="form"
                                sx={{
                                  mt: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 2,
                                }}
                              >
                                {/* Phone Number Input */}
                                <TextField
                                  id="phone-number"
                                  label="Phone Number"
                                  variant="outlined"
                                  fullWidth
                                  // onKeyPress={handleNumericOnly}
                                  maxLength={10}
                                />

                                {/* Reason Textarea */}
                                <TextField
                                  id="reason"
                                  label="Reason"
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  rows={4}
                                />

                                {/* Buttons */}
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    gap: 1,
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      // Add your submission logic here
                                      handleClose();
                                    }}
                                  >
                                    Request Call
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleClose}
                                  >
                                    Close
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </Modal>
                        </ul>
                      </li>
                            <li
                              className="list-inline-item p-1 fs-6"
                              style={{ cursor: "pointer" }}
                              onClick={() => navigateTo("/Claim")}
                            >
                              Claim
                            </li>
                          </ul>
                        </div>

                        {/* Mobile Menu */}
                        <div className="col-lg-3 col-4 d-lg-none text-end">
                          <img
                            src={hambergar}
                            alt="Menu"
                            style={{
                              cursor: "pointer",
                              width: "30px",
                              height: "30px",
                            }}
                            onClick={() => settoggle(!toogle)}
                          />
                        </div>
                      </div>
                    </div>

                    {/* Contact Section */}
                    <div className="col-lg-2 d-none d-lg-flex  justify-content-end pe-5">
                      <span
                        className="fs-6 fw-bold "
                        style={{
                          cursor: "pointer",
                          borderBottom: "2px solid black", // Adjust thickness and color as needed
                          // paddingBottom: "4px",
                        }}
                      >
                        Contact
                      </span>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {toogle === true ? (
              <>
                <div className="container">
                  <div className="row mt-5">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-8  text-start ps-5">
                          <span className="fs-3">Policy dekhoo</span>
                        </div>
                        <div className="col-4">
                          <img
                            src={cross}
                            alt=""
                            onClick={(e) => settoggle(!toogle)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-7 col-1">
                      <ul
                        className=""
                        style={{ listStyle: "none", textAlign: "left" }}
                      >
                        <li
                          className=" p-2  fs-6"
                          style={{ cursor: "pointer" }}
                          onClick={() => navigateTo("/Claim")}
                        >
                          Home
                        </li>
                        <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                          PD Advantages
                        </li>
                        <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                          Customer Rating
                        </li>
                        <li className=" p-2 fs-6" style={{ cursor: "pointer" }}  onClick={() => navigateTo("/About")}>
                          About Us
                        </li>
                        <li className="list-inline-item dropdown p-1 fs-6">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ cursor: "pointer" }}
                        >
                          Support
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                          style={{ minWidth: "200px" }}
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              <img src={Callsupport} className="me-2" alt="" />
                              Call Us
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="mailto:info@policydekhoo.com"
                            >
                              <img
                                src={Mailsupport}
                                className="me-2"
                                alt="Mail Support"
                              />
                              Mail Us
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://wa.me/7734007799"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={Whatupsupport}
                                className="me-2"
                                alt="WhatsApp Support"
                              />
                              WhatsApp
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleOpen();
                              }}
                            >
                              <img
                                src={Callback}
                                className="me-2"
                                alt="Callback Icon"
                              />
                              Call Back
                            </a>
                          </li>

                          {/* Modal */}
                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              {/* Modal Content */}
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                              >
                                Request a Call Back
                              </Typography>
                              <Box
                                component="form"
                                sx={{
                                  mt: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 2,
                                }}
                              >
                                {/* Phone Number Input */}
                                <TextField
                                  id="phone-number"
                                  label="Phone Number"
                                  variant="outlined"
                                  fullWidth
                                  // onKeyPress={handleNumericOnly}
                                  maxLength={10}
                                />

                                {/* Reason Textarea */}
                                <TextField
                                  id="reason"
                                  label="Reason"
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  rows={4}
                                />

                                {/* Buttons */}
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    gap: 1,
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      // Add your submission logic here
                                      handleClose();
                                    }}
                                  >
                                    Request Call
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleClose}
                                  >
                                    Close
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </Modal>
                        </ul>
                      </li>
                        <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                          Claim
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-12">
                      <span className="get p-2" onClick={scrollToContactForm}>
                        Contact Us
                      </span>
                    </div>
                  </div>

                  <div className="row fixed-bottom">
                    <div className="col-12">
                      <div className=" ">
                        <span>
                          © 2020 Policy Dekhoo | Powered by Policy Dekhoo
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {toogle === false ? (
              <>
                <div className="container-fluid">
                  <div className="row ">
                    <div className="col-lg-10 ">
                      <div className="text-start  mx-5">
                        <span
                          className="fw-lighter"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigateTo("/");
                          }}
                        >
                          Home&#62;
                        </span>
                        <span className="" style={{ fontWeight: "400" }}>
                          Personal Accident
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-7  ps-5">
                      <div className="row">
                        <div className="col-md-5 col-6">
                          <hr
                            style={{ height: "2px", color: "#47C6EE" }}
                            className="w-100"
                          />
                        </div>
                        <div className="col-md-6 col-6">
                          <hr style={{ height: "2px" }} className="w-100" />
                        </div>
                      </div>
                      <div className="heade">
                        <div className="w-100">
                          <p className="fs-2" style={{ textAlign: "left" }}>
                            Protect Yourself with Insurance Catered to{" "}
                            <span className="fw-bold"> your loved ones.</span>
                          </p>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-lg-6 col-6 text-end">
                          <span
                            className="fs-4 "
                            onClick={() => handleGenderChange("male")}
                            style={{ cursor: "pointer" }}
                          >
                            Male
                          </span>
                        </div>

                        <div className="col-lg-6 col-6 text-start ">
                          <span
                            className="fs-4"
                            onClick={() => handleGenderChange("female")}
                            style={{ cursor: "pointer" }}
                          >
                            Female
                          </span>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-lg-6 col-6">
                          <div className="">
                            <button
                              className="w-50 p-3 male"
                              style={{ border: "none", background: "#fff" }}
                              onClick={
                                gender === "male"
                                  ? handleMaleClick
                                  : handleFemaleClick
                              }
                            >
                              <img
                                src={
                                  gender === "male"
                                    ? changesImage
                                    : currentImage
                                }
                                alt=""
                                className=""
                              />
                              self
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-6 col-6">
                          <div className="">
                            <button
                              className="w-50 p-3 female "
                              style={{ border: "none", background: "#fff" }}
                              onClick={
                                gender === "male"
                                  ? handleFemaleClick
                                  : handleMaleClick
                              }
                            >
                              <img
                                src={
                                  gender === "male"
                                    ? currentImage
                                    : changesImage
                                }
                                alt=""
                                className=""
                              />
                              Spouse
                            </button>
                          </div>
                        </div>
                      </div>
                      <form action="">
                        <div className="row mt-4">
                          <div className="col-lg-6">
                            <div className="text-start">
                              <label htmlFor="" className="mb-3 fw-bold">
                                Self Occupation
                                <div>
                            <label htmlFor="" className="from-label fw-bold">
                            स्व-व्यवसाय
                            </label>
                          </div>
                              </label>
                              <select
                                className="form-select p-3"
                                required
                                name="Self_Occupation"
                                value={PersonalAccident.Self_Occupation}
                                onChange={(e) => {
                                  addValues(e);
                                }}
                              >
                                <option value="">Enter Occupation</option>
                                <option value="Salaried">Salaried</option>
                                <option value="Self Employed">
                                  Self Employed
                                </option>
                              </select>
                            </div>
                          </div>
                          {showSpouseOccupation && (
                            <div className="col-lg-6 ">
                              <div className="text-start">
                                <label htmlFor="" className="mb-3 fw-bold">
                                  Spouse Occupation
                                  <div>
                            <label htmlFor="" className="from-label fw-bold">
                            जीवनसाथी का व्यवसाय
                            </label>
                          </div>
                                </label>
                                <select
                                  className="form-select p-3"
                                  required
                                  name="Spouse_Occupation"
                                  value={PersonalAccident.Spouse_Occupation}
                                  onChange={(e) => {
                                    addValues(e);
                                  }}
                                >
                                  <option value="">Enter Occupation</option>
                                  <option value="Salaried">Salaried</option>
                                  <option value="Self Employed">
                                    Self Employed
                                  </option>
                                </select>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="row mt-4">
                          <div className="col-lg-12">
                            <div className="text-start">
                              <label htmlFor="" className="mb-3 fw-bold">
                                Annual Income
                                <div>
                            <label htmlFor="" className="from-label fw-bold">
                            वार्षिक आय
                            </label>
                          </div>
                              </label>
                              <input
                                type="text"
                                className="form-control p-3"
                                id=""
                                placeholder="Enter Your Annual Income"
                                required
                                maxLength={"8"}
                                onKeyPress={handleNumericOnly}
                                name="Annual_Income"
                                value={PersonalAccident.Annual_Income}
                                onChange={(e) => {
                                  addValues(e);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-lg-12 mt-4 mb-5">
                            <div className="row ">
                              <div className="text-start">
                                <label htmlFor="" className="mb-3 fw-bold">
                                  Pin Code
                                  <div>
                            <label htmlFor="" className="from-label fw-bold">
                            पिन कोड
                            </label>
                          </div>
                                </label>
                                <input
                                  type="text"
                                  className="form-control p-3"
                                  id=""
                                  placeholder="Enter Your Pincode"
                                  required
                                  maxLength={"6"}
                                  onKeyPress={handleNumeric}
                                  name="Pincode"
                                  value={PersonalAccident.Pincode}
                                  onChange={(e) => {
                                    addValues(e);
                                    handleNumeric(e);
                                  }}
                                />
                                {pincodError && (
                                  <span style={{ color: "red" }}>
                                    {pincodError}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <button
                            className="p-3 w-25 fs-4 but19 mb-5"
                            onClick={(e) => {
                              handlesubmit1(e);
                              proceedform();
                            }}
                          >
                            Next
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="col-sm-5 ps-5 mt-5 d-none d-md-block">
                      <div className="">
                        <img src={doc} alt="" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container-fluid">
                  <div className="row mt-5 mx-5">
                    <div className="col-lg-12 text-start ">
                      <h2>Personal Insurance Plan</h2>
                    </div>
                    <div className="row ">
                      <div className="col-lg-12 ">
                        <div className="text-start">
                          <p className="fs-4">
                            Safeguard yourself and your loved ones from the
                            financial repercussions of accidents with Policy
                            dekhoo's accident insurance. Our policies offer
                            coverage for accidental death, permanent total
                            disability, temporary total disability, and medical
                            expenses resulting from accidents.
                          </p>
                        </div>

                        <div className="text-start w-75">
                          <p className="fs-4">
                            Choose from a variety of coverage options and
                            add-ons to tailor your policy to your specific
                            needs. With Policy dekhoo, you can rest assured
                            knowing that you are financially protected in the
                            event of an unforeseen accident. you and your loved
                            ones are covered with Policy dekhoo's reliable
                            accident insurance.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className='container-fluid'>

            <div className='row px-5 mb-5 ps-3'>
                        <div className='col-lg-12'>
                            <h5 style={{textAlign:"justify"}} className='fs-1 '>FAQ</h5>
                        </div>
                    </div>
           <div className='row px-5 ps-3'>
                        <div className='col-lg-8 '>
                            <div className='row '>
                                <div className='col-lg-12'>
                                <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Why do I need insurance?</Accordion.Header>
                                    <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>How is insurance premium calculated?</Accordion.Header>
                                    <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>What is a waiting period in insurance?</Accordion.Header>
                                    <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>How do I file an insurance claim?</Accordion.Header>
                                    <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Can I customize my insurance coverage?</Accordion.Header>
                                    <Accordion.Body>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                    culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>
                                </div>
                            </div>
                        </div>


                        <div className='col-sm-4 d-none d-md-block'>
                            <div>
                                <img src={docques} alt="" />
                            </div>
                        </div>
                    </div>
                </div> */}

                <footer className="container-fluid backgroundtheme mt-5 ">
                  <div className="row p-5">
                    {/* Logo Section */}
                    <div className="col-md-7 text-center text-md-start mb-4 mb-md-0">
                      <div
                        className="mb-3 "
                        onClick={() => {
                          navigateTo("/");
                        }}
                      >
                        <img
                          src={Footerlogo}
                          alt="Footer Logo"
                          style={{ maxWidth: "8vw" }}
                          className="footerlog"
                        />
                      </div>
                      <div className=" justify-content-center align-items-center w-50 d-none d-sm-block">
                        <span className="text-white">
                          Trust us for peace of mind and reliable protection,
                          backed by our unwavering commitment to your
                          satisfaction and security.
                        </span>
                      </div>
                      <div className=" justify-content-center align-items-center d-block d-sm-none">
                        <span className="text-white">
                          Trust us for peace of mind and reliable protection,
                          backed by our unwavering commitment to your
                          satisfaction and security.
                        </span>
                      </div>
                    </div>

                    {/* Navigation Links */}
                    <div className="col-md-5 mt-4	d-none d-sm-block">
                      <div className="row">
                        {/* First Column */}
                        <div className="col-sm-6 text-center text-sm-start mb-4 mb-sm-0">
                          <ul className="list-unstyled ">
                            <li
                              className="mb-3 fw-bold fs-6"
                              style={{ cursor: "pointer", color: "white" }} 
                              onClick={() => navigateTo("/")}
                            >
                              Home
                            </li>
                            <li
                              className="mb-3 fw-bold fs-6"
                              style={{ cursor: "pointer", color: "white" }}
                            >
                              PD Advantages
                            </li>
                            <li
                              className="mb-3 fw-bold fs-6"
                              style={{ cursor: "pointer", color: "white" }}
                            >
                              Customer Rating
                            </li>
                            <li
                              className="mb-3 fw-bold fs-6"
                              style={{ cursor: "pointer", color: "white" }} 
                              onClick={() => navigateTo("/About")}
                            >
                              About Us
                            </li>
                          </ul>
                        </div>

                        {/* Second Column */}
                        <div className="col-sm-6 text-center text-sm-start">
                          <ul className="list-unstyled ">
                            <li
                              className="mb-3 fw-bold fs-6"
                              style={{ cursor: "pointer", color: "white" }}
                            >
                              Support
                            </li>
                            <li
                              className="mb-3 fw-bold fs-6"
                              style={{ cursor: "pointer", color: "white" }}
                              onClick={() => navigateTo("/Claim")}>
                              Claim
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </footer>

                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <h5 className="fs-6">
                        All Rights Reserved | Developed By{" "}
                        <a
                          href="https://www.unairesync.com/"
                          className="link"
                          target="blank"
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          Unaire Sync
                        </a>
                      </h5>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </>
  );
}

export default Personalloneform;
