import React from "react";
// import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { useState, useRef } from "react";
import "./CarForm2.css";
import carin from "../resources/in.svg";
// import Accordion from "react-bootstrap/Accordion";
// import question from "../resources/ques.svg";
import log from "../resources/Logo.svg";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import  {AdapterDayjs}  from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate, useParams } from "react-router-dom";
import hambergar from "../resources/hambarger.svg";
import cross from "../resources/cross.svg";
import TextField from "@mui/material/TextField";
import trans from "../resources/englishtohindi.svg";
import Footerlogo from "../resources/pollicydekhoo-Cdr-jpg-300x300 1 (1).png";
import Callsupport from "../resources/callsupport.svg";
import Callback from "../resources/callbacksupport.svg";
import Whatupsupport from "../resources/whatupsupport.svg";
import Mailsupport from "../resources/mailsupport.svg";
import { Modal, Box, Typography, Button } from "@mui/material";

function CarForm2() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [selectedYear, setSelectedYear] = useState(null);
  const [toogle, settoggle] = useState(false);

  const contactFormRef = useRef(null);

  // Function to scroll to the contact form
  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleChange = (e) => {
    setSelectedYear(parseInt(e.target.value));
  };

  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year >= 2000; year--) {
    years.push(year);
    // setCarInsurance2Data({...carInsurance2Data,Year_Of_Manufacturing:event.target.value})
  }

  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };

  // const [selectYes, setselectYes] = useState(null);

  // const handleRadioClick = (value) => {
  //   if (value === selectYes) {
  //     setselectYes(null);
  //   } else {
  //     setselectYes(value);
  //   }
  // };

  const [selectYes, setSelectYes] = useState("");

  const handleRadioClick = (value) => {
    setSelectYes(value);
    if (value === "No") {
      setCarInsurance2Data({
        ...carInsurance2Data,
        Any_Claims_This_Year: value,
      });
    } else {
      setCarInsurance2Data({
        ...carInsurance2Data,
        Any_Claims_This_Year: value,
        Last_Year_NCB: null,
      });
    }
  };

  const handleNextButtonClick = () => {
    const form = document.querySelector("form");
    if (form.checkValidity()) {
      // Proceed to the next page
      const car_insurance_with_variables = {
        ...carInsurance2Data,
        iddupd: iddupd,
        rgno: rgno,
      };
      const serializedObj = encodeURIComponent(
        JSON.stringify(car_insurance_with_variables)
      );
      navigateTo(`/CarForm/CarForm2/CarForm3/${serializedObj}`);
    } else {
      // Show validation errors
      form.reportValidity();
    }
  };

  // console.log(carInsurance2Data)
  const { obj } = useParams();
  const obj1 = JSON.parse(decodeURIComponent(obj));

  console.log(obj1, "++++++++++++++++++++++++++");
  const navigateTocarForm1 = () => {
    const serializedObj = encodeURIComponent(JSON.stringify(obj1));
    navigateTo(`/CarForm/${serializedObj}`);
  };

  const [carInsurance2Data, setCarInsurance2Data] = useState({
    Policy_Expiry_Date: "",
    Year_Of_Manufacturing: "",
    Previous_Insurer: "",
    Any_Claims_This_Year: "",
    Last_Year_NCB: "",
  });

  const iddupd = obj1.id;
  const rgno = obj1.Registration_No;
  const tableName = "car_insurance";

  const addValues = (e) => {
    setCarInsurance2Data({
      ...carInsurance2Data,
      [e.target.name]: e.target.value,
    });
    console.log(e.target.value, e.target.name);
  };

  const handlesubmit2 = async () => {
    console.log("data is:", carInsurance2Data);
    try {
      const response = await fetch(
        `https://policydekhoo-backend.onrender.com/Updatevechiledata?tableName=${tableName}&Registration_No=${rgno}&id=${iddupd}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(carInsurance2Data),
        }
      );

      if (response.ok) {
        console.log("Data updated successfully");
      } else {
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const [language, setLanguage] = useState("en");
  const toggleLanguage = () => {
    setLanguage((prev) => (prev === "en" ? "hi" : "en"));
  };

  return (
    <div>
      {toogle === false ? (
        <>
          <div className="container-fluid mb-5 ">
            <div className="row align-items-center">
              {/* Logo Section */}
              <div
                className="col-8 col-lg-3 mt-3 ps-5 d-flex align-items-center"
                onClick={() => {
                  navigateTo("/");
                }}
              >
                <img
                  src={log}
                  alt="Logo"
                  style={{ width: "12vw", maxWidth: "50px" }}
                />
                <span className="fs-5 ms-2">Policy Dekhoo</span>
              </div>

              {/* Navigation Links */}
              <div className="col-lg-7 col-4 d-flex justify-content-end align-items-center">
                <div className="row align-items-center w-100">
                  {/* Desktop Menu */}
                  <div className="col-lg-9 d-none d-lg-block">
                    <ul className="list-inline mb-0">
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigateTo("/");
                        }}
                      >
                        Home
                      </li>
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        PD Advantages
                      </li>
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Customer Rating
                      </li>
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigateTo("/About")}
                      >
                        About Us
                      </li>
                      <li className="list-inline-item dropdown p-1 fs-6">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ cursor: "pointer" }}
                        >
                          Support
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                          style={{ minWidth: "200px" }}
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              <img src={Callsupport} className="me-2" alt="" />
                              Call Us
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="mailto:info@policydekhoo.com"
                            >
                              <img
                                src={Mailsupport}
                                className="me-2"
                                alt="Mail Support"
                              />
                              Mail Us
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://wa.me/7734007799"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={Whatupsupport}
                                className="me-2"
                                alt="WhatsApp Support"
                              />
                              WhatsApp
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleOpen();
                              }}
                            >
                              <img
                                src={Callback}
                                className="me-2"
                                alt="Callback Icon"
                              />
                              Call Back
                            </a>
                          </li>

                          {/* Modal */}
                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              {/* Modal Content */}
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                              >
                                Request a Call Back
                              </Typography>
                              <Box
                                component="form"
                                sx={{
                                  mt: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 2,
                                }}
                              >
                                {/* Phone Number Input */}
                                <TextField
                                  id="phone-number"
                                  label="Phone Number"
                                  variant="outlined"
                                  fullWidth
                                  // onKeyPress={handleNumericOnly}
                                  maxLength={10}
                                />

                                {/* Reason Textarea */}
                                <TextField
                                  id="reason"
                                  label="Reason"
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  rows={4}
                                />

                                {/* Buttons */}
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    gap: 1,
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      // Add your submission logic here
                                      handleClose();
                                    }}
                                  >
                                    Request Call
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleClose}
                                  >
                                    Close
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </Modal>
                        </ul>
                      </li>
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigateTo("/Claim")}
                      >
                        Claim
                      </li>
                    </ul>
                  </div>

                  {/* Mobile Menu */}
                  <div className="col-lg-3 col-4 d-lg-none text-end">
                    <img
                      src={hambergar}
                      alt="Menu"
                      style={{
                        cursor: "pointer",
                        width: "30px",
                        height: "30px",
                      }}
                      onClick={() => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>

              {/* Contact Section */}
              <div className="col-lg-2 d-none d-lg-flex  justify-content-end pe-5">
                <span
                  className="fs-6 fw-bold "
                  style={{
                    cursor: "pointer",
                    borderBottom: "2px solid black", // Adjust thickness and color as needed
                    // paddingBottom: "4px",
                  }}
                >
                  Contact
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === true ? (
        <>
          <div className="container menuu">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-8  text-start ps-5">
                    <span className="fs-3">Policy dekhoo</span>
                  </div>
                  <div className="col-4">
                    <img
                      src={cross}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-7 col-1">
                <ul
                  className=""
                  style={{ listStyle: "none", textAlign: "left" }}
                >
                  <li
                    className=" p-2  fs-6"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigateTo("/")}
                  >
                    Home
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    PD Advantages
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Customer Rating
                  </li>
                  <li
                    className=" p-2 fs-6"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigateTo("/About")}
                  >
                    About Us
                  </li>
                  <li className="list-inline-item dropdown p-1 fs-6">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ cursor: "pointer" }}
                    >
                      Support
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                      style={{ minWidth: "200px" }}
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          <img src={Callsupport} className="me-2" alt="" />
                          Call Us
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="mailto:info@policydekhoo.com"
                        >
                          <img
                            src={Mailsupport}
                            className="me-2"
                            alt="Mail Support"
                          />
                          Mail Us
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="https://wa.me/7734007799"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={Whatupsupport}
                            className="me-2"
                            alt="WhatsApp Support"
                          />
                          WhatsApp
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleOpen();
                          }}
                        >
                          <img
                            src={Callback}
                            className="me-2"
                            alt="Callback Icon"
                          />
                          Call Back
                        </a>
                      </li>

                      {/* Modal */}
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          {/* Modal Content */}
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            Request a Call Back
                          </Typography>
                          <Box
                            component="form"
                            sx={{
                              mt: 2,
                              display: "flex",
                              flexDirection: "column",
                              gap: 2,
                            }}
                          >
                            {/* Phone Number Input */}
                            <TextField
                              id="phone-number"
                              label="Phone Number"
                              variant="outlined"
                              fullWidth
                              // onKeyPress={handleNumericOnly}
                              maxLength={10}
                            />

                            {/* Reason Textarea */}
                            <TextField
                              id="reason"
                              label="Reason"
                              variant="outlined"
                              fullWidth
                              multiline
                              rows={4}
                            />

                            {/* Buttons */}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                gap: 1,
                              }}
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  // Add your submission logic here
                                  handleClose();
                                }}
                              >
                                Request Call
                              </Button>
                              <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleClose}
                              >
                                Close
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Modal>
                    </ul>
                  </li>
                  <li
                    className="p-2 fs-6"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigateTo("/Claim")}
                  >
                    Claim
                  </li>
                </ul>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <span className="get p-2" onClick={scrollToContactForm}>
                  Contact Us
                </span>
              </div>
            </div>

            <div className="row fixed-bottom">
              <div className="col-12">
                <div className=" ">
                  <span>© 2020 Policy Dekhoo | Powered by Policy Dekhoo</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {toogle === true ? (
        <>
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-8 text-start ps-5">
                    <span className="fs-3">Policy dekhoo</span>
                  </div>
                  <div className="col-4">
                    <img
                      src={cross}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-7">
                <ul
                  className=""
                  style={{ listStyle: "none", textAlign: "left" }}
                >
                  <li
                    className="p-2"
                    onClick={() => {
                      navigateTo("/");
                    }}
                  >
                    Home
                  </li>
                  <li className="p-2">Product</li>
                  <li className="p-2">Contact</li>
                </ul>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <span className="get p-2">Get Quotes</span>
              </div>
            </div>

            <div className="row fixed-bottom">
              <div className="col-12">
                <div className=" ">
                  <span>© 2020 Policy Dekhoo | Powered by Policy Dekhoo</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {toogle === false ? (
        <>
          <div className="container-fluid">
            <div className="row text-start p-3">
              <div className="row">
                <div className="col-6 mx-5">
                  <span
                    onClick={() => {
                      navigateTo("/");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {language === "en" ? "Home >" : "होम >"}
                  </span>
                  <span
                    className="fw-lighter"
                    onClick={() => {
                      navigateTocarForm1();
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {language === "en" ? "Car Insurance" : "कार बीमा"}
                  </span>
                  &#62;
                  <span style={{ fontWeight: "500", cursor: "pointer" }}>
                    {language === "en" ? "Previous Insurer" : "पिछला बीमाकर्ता"}
                  </span>
                </div>
                {/* <div className="col-4">
                  <img
                    src={trans}
                    style={{ width: "2vw", cursor: "pointer" }}
                    alt="Translate"
                    onClick={toggleLanguage}
                  />
                </div> */}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-7 p-3">
                <div className="row mx-5">
                  <div className="col-sm-4 col-4">
                    <hr
                      style={{ height: "2px", border: "" }}
                      className="w-100"
                    />
                  </div>
                  <div className="col-md-4 col-4">
                    <hr
                      style={{ height: "2px", color: "#2D75E0" }}
                      className="w-100"
                    />
                  </div>
                  <div className="col-md-4 col-4">
                    <hr style={{ height: "2px" }} className="w-100" />
                  </div>
                </div>
                <div className="m-0 w-75 ps-5">
                  <p className="fs-3" style={{ textAlign: "left" }}>
                    {language === "en" ? (
                      <>
                        Get Your Car Insurance Quote Tailored to{" "}
                        <span className="fw-bold">your requirements.</span>
                      </>
                    ) : (
                      <>
                        अपनी आवश्यकताओं के अनुसार अपनी{" "}
                        <span className="fw-bold">
                          कार बीमा कोटेशन प्राप्त करें।
                        </span>
                      </>
                    )}
                  </p>
                </div>

                <div>
                  <form action="">
                    <div className="row ps-5">
                      <div className="col-md-6 text-start">
                        <div>
                          <label htmlFor="" className=" fw-bold">
                            {language === "en"
                              ? "Policy Expiry Date"
                              : "पॉलिसी समाप्ति तिथि डाले"}
                          </label>
                          <div className="mb-3">
                            <label htmlFor="" className="fw-bold">
                              पॉलिसी समाप्ति तिथि डाले
                            </label>
                          </div>
                        </div>
                        <div style={{ marginTop: "8px" }}>
                          <TextField
                            id="outlined-basic"
                            type="date"
                            size="medium"
                            fullWidth
                            variant="outlined"
                            name="Policy_Expiry_Date"
                            value={carInsurance2Data.Policy_Expiry_Date}
                            onChange={(e) => {
                              addValues(e);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-6 text-start">
                        <div>
                          <label htmlFor="" className=" fw-bold">
                            {language === "en"
                              ? "Year Of Manufacturing"
                              : "निर्माण का वर्ष"}
                          </label>
                          <div className="mb-3">
                            <label htmlFor="" className="fw-bold">
                              निर्माण का वर्ष
                            </label>
                          </div>
                        </div>
                        <div>
                          <select
                            className="form-control p-3"
                            name="Year_Of_Manufacturing"
                            value={carInsurance2Data.Year_Of_Manufacturing}
                            onChange={(e) => {
                              addValues(e);
                            }}
                          >
                            <option value="">
                              {language === "en" ? "Select year" : "वर्ष चुनें"}
                            </option>
                            {years.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row ps-5">
                      <div className="col-md-6 text-start">
                        <div>
                          <label htmlFor="" className="fw-bold ">
                            {language === "en"
                              ? "Previous Insurer"
                              : "बीमा कंपनी का चुनाव करे"}
                          </label>
                          <div className="mb-3">
                            <label htmlFor="" className="fw-bold">
                              बीमा कंपनी का चुनाव करे
                            </label>
                          </div>
                        </div>
                        <select
                          className="form-select p-3"
                          required
                          name="Previous_Insurer"
                          value={carInsurance2Data.Previous_Insurer}
                          onChange={(e) => {
                            addValues(e);
                          }}
                        >
                          <option value="">
                            {language === "en"
                              ? "Select an option"
                              : "विकल्प चुनें"}
                          </option>
                          <option value="Acko General Insurance Limited">
                            {language === "en"
                              ? "Acko General Insurance Limited"
                              : "Acko जनरल इंश्योरेंस लिमिटेड"}
                          </option>
                          <option value="Insurance Company of India Limited">
                            {language === "en"
                              ? "Insurance Company of India Limited"
                              : "भारत इंश्योरेंस कंपनी लिमिटेड"}
                          </option>
                          <option value="Bajaj Allianz General Insurance Company Limited">
                            {language === "en"
                              ? "Bajaj Allianz General Insurance Company Limited"
                              : "बजाज आलियांज जनरल इंश्योरेंस कंपनी लिमिटेड"}
                          </option>
                          <option value="HDFC ERGO General Insurance Company Limited">
                            {language === "en"
                              ? "HDFC ERGO General Insurance Company Limited"
                              : "एचडीएफसी ईआरजीओ जनरल इंश्योरेंस कंपनी लिमिटेड"}
                          </option>
                          <option value="ICICI Lombard General Insurance Company Limited">
                            {language === "en"
                              ? "ICICI Lombard General Insurance Company Limited"
                              : "आईसीआईसीआई लोम्बार्ड जनरल इंश्योरेंस कंपनी लिमिटेड"}
                          </option>
                          <option value="Kotak Mahindra General Insurance Company Limited">
                            {language === "en"
                              ? "Kotak Mahindra General Insurance Company Limited"
                              : "कोटक महिंद्रा जनरल इंश्योरेंस कंपनी लिमिटेड"}
                          </option>
                          <option value="Max Bupa Health Insurance Company Limited">
                            {language === "en"
                              ? "Max Bupa Health Insurance Company Limited"
                              : "मैक्स बुपा हेल्थ इंश्योरेंस कंपनी लिमिटेड"}
                          </option>
                          <option value="New India Assurance Company Limited">
                            {language === "en"
                              ? "New India Assurance Company Limited"
                              : "न्यू इंडिया एश्योरेंस कंपनी लिमिटेड"}
                          </option>
                          <option value="Religare Health Insurance Company Limited">
                            {language === "en"
                              ? "Religare Health Insurance Company Limited"
                              : "रिलigare हेल्थ इंश्योरेंस कंपनी लिमिटेड"}
                          </option>
                          <option value="SBI General Insurance Company Limited">
                            {language === "en"
                              ? "SBI General Insurance Company Limited"
                              : "एसबीआई जनरल इंश्योरेंस कंपनी लिमिटेड"}
                          </option>
                          <option value="Tata AIG General Insurance Company Limited">
                            {language === "en"
                              ? "Tata AIG General Insurance Company Limited"
                              : "टाटा एआईजी जनरल इंश्योरेंस कंपनी लिमिटेड"}
                          </option>
                          <option value="The Oriental Insurance Company Limited">
                            {language === "en"
                              ? "The Oriental Insurance Company Limited"
                              : "द ओरिएंटल इंश्योरेंस कंपनी लिमिटेड"}
                          </option>
                          <option value="United India Insurance Company Limited">
                            {language === "en"
                              ? "United India Insurance Company Limited"
                              : "यूनाइटेड इंडिया इंश्योरेंस कंपनी लिमिटेड"}
                          </option>
                          <option value="Wealth Insurance Company Limited">
                            {language === "en"
                              ? "Wealth Insurance Company Limited"
                              : "वेल्थ इंश्योरेंस कंपनी लिमिटेड"}
                          </option>
                        </select>
                      </div>

                      <div className="col-md-6 text-start">
                        <div>
                          <label htmlFor="" className=" mt-3 fw-bold">
                            {language === "en"
                              ? "Any Claims This Year"
                              : "आपने कोई क्लेम लिया है"}
                          </label>
                          <div className="mb-3">
                            <label htmlFor="" className="fw-bold">
                              आपने कोई क्लेम लिया है
                            </label>
                          </div>
                        </div>
                        <div className="text-end mt-3 text-center">
                          <div>
                            <input
                              type="radio"
                              id=""
                              name="Any_Claims_This_Year"
                              value={carInsurance2Data.Any_Claims_This_Year}
                              className=""
                              required
                              checked={selectYes === "Yes"}
                              onClick={() => handleRadioClick("Yes")}
                            />
                            <label for="" className="me-5 ps-3">
                              {language === "en" ? "Yes" : "हाँ"}
                            </label>

                            <input
                              type="radio"
                              id=""
                              checked={selectYes === "No"}
                              onClick={() => handleRadioClick("No")}
                              required
                              name="Any_Claims_This_Year"
                              value={carInsurance2Data.Any_Claims_This_Year}
                            />
                            <label for="" className="ps-3">
                              {language === "en" ? "No" : "नहीं"}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row ps-5">
                      <div className="col-md-6 mb-5 text-sm-start text-center">
                        <div>
                          <label htmlFor="" className=" mt-3 fw-bold">
                            {language === "en"
                              ? "Last Year NCB"
                              : "नो क्लेम बोनस का चुनाव करे"}
                          </label>
                          <div className="mb-3">
                            <label htmlFor="" className="fw-bold">
                              नो क्लेम बोनस का चुनाव करे
                            </label>
                          </div>
                        </div>
                        <div>
                          <select
                            className="form-select p-3"
                            required
                            disabled={selectYes === "Yes"}
                            name="Last_Year_NCB"
                            value={carInsurance2Data.Last_Year_NCB}
                            onChange={(e) => {
                              addValues(e);
                            }}
                          >
                            <option value="">
                              {language === "en"
                                ? "Last Year NCB"
                                : "पिछले वर्ष का NCB"}
                            </option>
                            <option value="0">0</option>
                            <option value="20">20</option>
                            <option value="25">25</option>
                            <option value="35">35</option>
                            <option value="45">45</option>
                            <option value="50">50</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <button
                      className="but2 w-25 p-2"
                      onClick={() => {
                        handlesubmit2();
                        if (handleNextButtonClick()) {
                          navigateTo("/CarForm/CarForm2/CarForm3");
                        }
                      }}
                    >
                      {language === "en" ? "Continue" : "जारी रखें"}
                    </button>
                  </form>
                </div>
              </div>

              <div className="col-md-4 ps-5 d-none d-lg-block">
                <div>
                  <img src={carin} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* HPI */}
          <div className="container-fluid">
            <div className="row  mx-5">
              <div className="col-md-12   text-start p-4">
                <h2>Car Insurance Plan</h2>
              </div>
              <div className="row ">
                <div className="col-md-12 ">
                  <div className="text-start ">
                    <p className="fs-4">
                      Safeguard your vehicle and financial security with Policy
                      dekhoo's robust car insurance plans. Our comprehensive
                      coverage shields you against a myriad of risks, including
                      accidents, theft, fire, natural disasters, and third-party
                      liabilities. Customise your plan with versatile add-ons
                      like roadside assistance, ensuring tailored protection for
                      your specific needs.
                    </p>
                  </div>

                  <div className="text-start ">
                    <p className="fs-4">
                      In the event of an unfortunate incident, our streamlined
                      claims process ensures minimal disruption to your routine.
                      With Policy dekhoo, you can navigate the roads with
                      confidence, knowing that you're backed by a reliable
                      insurance provider committed to your peace of mind. Trust
                      us to protect your journey.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="container-fluid">
        <div className="row px-5 mb-5 ">
          <div className="col-md-12 ps-5">
            <h5 style={{ textAlign: "justify" }} className="fs-1 ">
              FAQ
            </h5>
          </div>
        </div>
        <div className="row px-5 ">
          <div className="col-lg-8 ">
            <div className="row ">
              <div className="col-md-12">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Why do I need insurance?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      How is insurance premium calculated?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      What is a waiting period in insurance?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      How do I file an insurance claim?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Can I customize my insurance coverage?
                    </Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-none d-lg-block">
            <div>
              <img src={question} alt="" />
            </div>
          </div>
        </div>
      </div> */}

          {/* Footer */}

          <footer className="container-fluid backgroundtheme mt-5 ">
            <div className="row p-5">
              {/* Logo Section */}
              <div className="col-md-7 text-center text-md-start mb-4 mb-md-0">
                <div
                  className="mb-3 "
                  onClick={() => {
                    navigateTo("/");
                  }}
                >
                  <img
                    src={Footerlogo}
                    alt="Footer Logo"
                    style={{ maxWidth: "8vw" }}
                    className="footerlog"
                  />
                </div>
                <div className=" justify-content-center align-items-center w-50 d-none d-sm-block">
                  <span className="text-white">
                    Trust us for peace of mind and reliable protection, backed
                    by our unwavering commitment to your satisfaction and
                    security.
                  </span>
                </div>
                <div className=" justify-content-center align-items-center d-block d-sm-none">
                  <span className="text-white">
                    Trust us for peace of mind and reliable protection, backed
                    by our unwavering commitment to your satisfaction and
                    security.
                  </span>
                </div>
              </div>

              {/* Navigation Links */}
              <div className="col-md-5 mt-4	d-none d-sm-block">
                <div className="row">
                  {/* First Column */}
                  <div className="col-sm-6 text-center text-sm-start mb-4 mb-sm-0">
                    <ul className="list-unstyled ">
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        Home
                      </li>
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        PD Advantages
                      </li>
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        Customer Rating
                      </li>
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        About Us
                      </li>
                    </ul>
                  </div>

                  {/* Second Column */}
                  <div className="col-sm-6 text-center text-sm-start">
                    <ul className="list-unstyled ">
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        Support
                      </li>
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        Claim
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </footer>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h5 className="fs-6">
                  All Rights Reserved | Developed By{" "}
                  <a
                    href="https://www.unairesync.com/"
                    className="link"
                    target="blank"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Unaire Sync
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default CarForm2;
