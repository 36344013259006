import React from "react";
import { useState, useRef, useEffect } from "react";
import "./Abut.css";
import { useNavigate } from "react-router-dom";
import log from "../resources/Logo.svg";
import hambergar from "../resources/hambarger.svg";
import cross from "../resources/cross.svg";
import Footerlogo from "../resources/pollicydekhoo-Cdr-jpg-300x300 1 (1).png"
import Callsupport from "../resources/callsupport.svg";
import Callback from "../resources/callbacksupport.svg";
import Whatupsupport from "../resources/whatupsupport.svg";
import Mailsupport from "../resources/mailsupport.svg";
import { Modal, Box, Typography, Button, TextField } from "@mui/material";


function AboutUs() {
  const [toogle, settoggle] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const contactFormRef = useRef(null);

  // Function to scroll to the contact form
  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    console.log("Scrolling to the top");
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };
  return (
    <div>
      {toogle === false ? (
        <>
           <div className="container-fluid mb-5 ">
            <div className="row align-items-center">
              {/* Logo Section */}
              <div className="col-8 col-lg-3 mt-3 ps-5 d-flex align-items-center" onClick={() => {
                    navigateTo("/");
                  }}>
                <img
                  src={log}
                  alt="Logo"
                  style={{ width: "12vw", maxWidth: "50px" }}
                />
                <span className="fs-5 ms-2">Policy Dekhoo</span>
              </div>

              {/* Navigation Links */}
              <div className="col-lg-7 col-4 d-flex justify-content-end align-items-center">
                <div className="row align-items-center w-100">
                  {/* Desktop Menu */}
                  <div className="col-lg-9 d-none d-lg-block">
                    <ul className="list-inline mb-0">
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigateTo("/");
                        }}
                      >
                        Home
                      </li>
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        PD Advantages
                      </li>
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Customer Rating
                      </li>
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigateTo("/About")}
                      >
                        About Us
                      </li>
                      <li className="list-inline-item dropdown p-1 fs-6">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ cursor: "pointer" }}
                        >
                          Support
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                          style={{ minWidth: "200px" }}
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              <img src={Callsupport} className="me-2" alt="" />
                              Call Us
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="mailto:info@policydekhoo.com"
                            >
                              <img
                                src={Mailsupport}
                                className="me-2"
                                alt="Mail Support"
                              />
                              Mail Us
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://wa.me/7734007799"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={Whatupsupport}
                                className="me-2"
                                alt="WhatsApp Support"
                              />
                              WhatsApp
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleOpen();
                              }}
                            >
                              <img
                                src={Callback}
                                className="me-2"
                                alt="Callback Icon"
                              />
                              Call Back
                            </a>
                          </li>

                          {/* Modal */}
                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              {/* Modal Content */}
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                              >
                                Request a Call Back
                              </Typography>
                              <Box
                                component="form"
                                sx={{
                                  mt: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 2,
                                }}
                              >
                                {/* Phone Number Input */}
                                <TextField
                                  id="phone-number"
                                  label="Phone Number"
                                  variant="outlined"
                                  fullWidth
                                  // onKeyPress={handleNumericOnly}
                                  maxLength={10}
                                />

                                {/* Reason Textarea */}
                                <TextField
                                  id="reason"
                                  label="Reason"
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  rows={4}
                                />

                                {/* Buttons */}
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    gap: 1,
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      // Add your submission logic here
                                      handleClose();
                                    }}
                                  >
                                    Request Call
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleClose}
                                  >
                                    Close
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </Modal>
                        </ul>
                      </li>
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={()=> navigateTo("/Claim")}
                      >
                        Claim
                      </li>
                    </ul>
                  </div>

                  {/* Mobile Menu */}
                  <div className="col-lg-3 col-4 d-lg-none text-end">
                    <img
                      src={hambergar}
                      alt="Menu"
                      style={{
                        cursor: "pointer",
                        width: "30px",
                        height: "30px",
                      }}
                      onClick={() => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>

              {/* Contact Section */}
              <div className="col-lg-2 d-none d-lg-flex  justify-content-end pe-5">
                <span
                  className="fs-6 fw-bold "
                  style={{
                    cursor: "pointer",
                    borderBottom: "2px solid black", // Adjust thickness and color as needed
                    // paddingBottom: "4px",
                  }}
                >
                  Contact
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === true ? (
        <>
          <div className="container menuu">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-8  text-start ps-5">
                    <span className="fs-3">Policy dekhoo</span>
                  </div>
                  <div className="col-4">
                    <img
                      src={cross}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-7 col-1">
                <ul
                  className=""
                  style={{ listStyle: "none", textAlign: "left" }}
                >
                  <li className=" p-2  fs-6" style={{ cursor: "pointer" }} onClick={()=>navigateTo("/")}>
                    Home
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    PD Advantages
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Customer Rating
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }} onClick={()=>navigateTo("/About")}>
                    About Us
                  </li>
                  <li className="list-inline-item dropdown p-1 fs-6">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ cursor: "pointer" }}
                        >
                          Support
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                          style={{ minWidth: "200px" }}
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              <img src={Callsupport} className="me-2" alt="" />
                              Call Us
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="mailto:info@policydekhoo.com"
                            >
                              <img
                                src={Mailsupport}
                                className="me-2"
                                alt="Mail Support"
                              />
                              Mail Us
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://wa.me/7734007799"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={Whatupsupport}
                                className="me-2"
                                alt="WhatsApp Support"
                              />
                              WhatsApp
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleOpen();
                              }}
                            >
                              <img
                                src={Callback}
                                className="me-2"
                                alt="Callback Icon"
                              />
                              Call Back
                            </a>
                          </li>

                          {/* Modal */}
                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              {/* Modal Content */}
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                              >
                                Request a Call Back
                              </Typography>
                              <Box
                                component="form"
                                sx={{
                                  mt: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 2,
                                }}
                              >
                                {/* Phone Number Input */}
                                <TextField
                                  id="phone-number"
                                  label="Phone Number"
                                  variant="outlined"
                                  fullWidth
                                  // onKeyPress={handleNumericOnly}
                                  maxLength={10}
                                />

                                {/* Reason Textarea */}
                                <TextField
                                  id="reason"
                                  label="Reason"
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  rows={4}
                                />

                                {/* Buttons */}
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    gap: 1,
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      // Add your submission logic here
                                      handleClose();
                                    }}
                                  >
                                    Request Call
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleClose}
                                  >
                                    Close
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </Modal>
                        </ul>
                      </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}  onClick={()=> navigateTo("/Claim")}>
                    Claim
                  </li>
                </ul>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <span className="get p-2" >Contact Us</span>
              </div>
            </div>

            <div className="row fixed-bottom">
              <div className="col-12">
                <div className=" ">
                  <span>© 2020 Policy Dekhoo | Powered by Policy Dekhoo</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {toogle === false ? (
        <>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-start ps-5">
                  <span onClick={() => {
                    navigateTo("/")
                  }} style={{ cursor: "pointer" }}>Home&#62;</span>
                  <span>About Us</span>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-start ps-5 mt-5">
                  <h1>About Us</h1>
                </div>
                <div className=" mt-5 ps-5 w-100" style={{textAlign:"left"}}>
                  <p className="fs-5">
                    Policy Dekhoo is not just another insurance platform; it's your trusted partner in navigating the often complex world of insurance with ease and confidence. We understand that choosing the right insurance coverage can be daunting, which is why we've made it our mission to simplify the process and provide you with transparent options tailored specifically to your unique needs.
                  </p>
                </div>

                <div className="mt-4 ps-5" style={{textAlign:"left"}}>
                  <p className="fs-5">
                    At Policy Dekhoo, we believe in empowerment through information. Our user-friendly platform is designed to put you in the driver's seat, allowing you to compare policies, understand coverage details, and make informed decisions with ease. Gone are the days of sifting through confusing jargon or feeling overwhelmed by endless options. With Policy Dekhoo, you'll have everything you need to make the best choice for yourself and your loved ones, right at your fingertips.                    </p>
                </div>

                <div className="mt-4 ps-5" style={{textAlign:"left"}}>
                  <p className="fs-5">
                    But we're more than just a website—we're a team of dedicated professionals committed to providing you with personalized guidance and exceptional service every step of the way. Whether you have questions about a specific policy, need help navigating the claims process, or simply want expert advice on how to best protect your assets, our knowledgeable team is here to assist you. We believe in building lasting relationships with our customers, and we'll go above and beyond to ensure that you have the protection you need, when you need it most.                  </p>
                </div>

                <div className="mt-4 ps-5" style={{textAlign:"left"}}>
                  <p className="fs-5">
                    From auto and home insurance to health and life coverage, Policy Dekhoo has you covered across all aspects of your life. Our comprehensive range of products and services ensures that you can find the perfect fit for your individual circumstances, helping you safeguard your assets and achieve true peace of mind.
                  </p>
                </div>

                <div className="mt-4 ps-5" style={{textAlign:"left"}}>
                  <p className="fs-5">
                    So why wait? Join us on the journey to a secure future today. Discover why thousands of customers trust Policy Dekhoo for their insurance needs and experience firsthand the simplicity, transparency, and stress-free shopping experience that we're known for. Welcome to Policy Dekhoo—where insurance shopping is not just simple, but empowering.                  </p>
                </div>
              </div>
            </div>
          </div>



          <footer className="container-fluid backgroundtheme mt-5 ">
            <div className="row p-5">
              {/* Logo Section */}
              <div className="col-md-7 text-center text-md-start mb-4 mb-md-0">
                <div className="mb-3">
                  <img
                    src={Footerlogo}
                    alt="Footer Logo"
                    style={{ maxWidth: "8vw" }}
                    className="footerlog"
                  />
                </div>
                <div className=" justify-content-center align-items-center w-50 d-none d-sm-block">
                  <span className="text-white">
                    Trust us for peace of mind and reliable protection, backed
                    by our unwavering commitment to your satisfaction and
                    security.
                  </span>
                </div>
                <div className=" justify-content-center align-items-center d-block d-sm-none">
                  <span className="text-white">
                    Trust us for peace of mind and reliable protection, backed
                    by our unwavering commitment to your satisfaction and
                    security.
                  </span>
                </div>
              </div>

              {/* Navigation Links */}
              <div className="col-md-5 mt-5 	d-none d-sm-block">
                <div className="row">
                  {/* First Column */}
                  <div className="col-sm-6 text-center text-sm-start mb-4 mb-sm-0">
                    <ul className="list-unstyled ">
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                        onClick={()=>navigateTo("/")}
                      >
                        Home
                      </li>
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        PD Advantages
                      </li>
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        Customer Rating
                      </li>
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                        onClick={()=>navigateTo("/About")}
                      >
                        About Us
                      </li>
                    </ul>
                  </div>

                  {/* Second Column */}
                  <div className="col-sm-6 text-center text-sm-start">
                    <ul className="list-unstyled ">
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        Support
                      </li>
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                        onClick={()=>navigateTo("/Claim")}
                      >
                        Claim
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </footer>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div>
                  <a
                    href="https://unairesync.com/"
                    style={{ color: "black", textDecoration: "none" }}
                    className="fs-6 "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    All Rights Reserved | Developed By UnaireSync
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default AboutUs;
