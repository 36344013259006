import React from "react";
// import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { useState, useRef } from "react";
import doc from "../resources/personaldcoc.svg";
// import { Accordion } from "react-bootstrap";
import "./Loan.css";
import logo from "../resources/Logo.svg";
// import docques from "../resources/docquestion.svg";
import hambergar from "../resources/hambarger.svg";
import cross from "../resources/cross.svg";
import { useNavigate, useParams } from "react-router-dom";
import female from "../resources/Fem.svg";
import male from "../resources/male.svg";
import Footerlogo from "../resources/pollicydekhoo-Cdr-jpg-300x300 1 (1).png"
import Callsupport from "../resources/callsupport.svg";
import Callback from "../resources/callbacksupport.svg";
import Whatupsupport from "../resources/whatupsupport.svg";
import Mailsupport from "../resources/mailsupport.svg";
import { Modal, Box, Typography, Button, TextField } from "@mui/material";


function PersonalForm2({ familyArr, obj1 }) {
  // console.log(props.familyArr);

  const [toogle, settoggle] = useState(false);
  const [selectYes, setselectYes] = useState(null);
  
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const handleRadioClick = (value) => {
    if (value === selectYes) {
      setPersonalAccident({
        ...PersonalAccident,
        Medical_Condition: value,
      });
      setselectYes(null);
    } else {
      setselectYes(value);
      setPersonalAccident({
        ...PersonalAccident,
        Medical_Condition: value,
      });
    }
  };

const contactFormRef = useRef(null);

// Function to scroll to the contact form
const scrollToContactForm = () => {
  if (contactFormRef.current) {
    contactFormRef.current.scrollIntoView({ behavior: "smooth" });
  }
};


  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };
  const handleNextButtonClick = () => {
    const form = document.querySelector("form");
    if (form.checkValidity()) {
      navigateTo("/personalLoanform/PersonalForm2/ThankYou");
    } else {
      form.reportValidity();
    }
  };

  // const {obj} = useParams();
  // const obj1 = JSON.parse(decodeURIComponent(obj));
  // console.log(obj1,"------------------------");

  // console.log(obj1)
  const [PersonalAccident, setPersonalAccident] = useState({
    Self_DOB: "",
    Spouse_DOB: null,
    Medical_Condition: "",
    Your_Name: "",
    Mobile_No: "",
  });

  const iddupd = obj1.id;
  const tableName = "personal_accident";

  const addValues = (e) => {
    setPersonalAccident({
      ...PersonalAccident,
      [e.target.name]: e.target.value,
    });
    console.log(PersonalAccident);
  };

  // console.log(props.familyArr,"-------------------------------")
  console.log(PersonalAccident);

  const handlesubmit2 = async () => {
    console.log("data is:", PersonalAccident);
    try {
      const response = await fetch(
        `https://policydekhoo-backend.onrender.com/Updatedata?tableName=${tableName}&id=${iddupd}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(PersonalAccident),
        }
      );

      if (response.ok) {
        console.log("Data updated successfully");
      } else {
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [date, setDate] = useState([]);
  console.log(date);
  const changeDate = (value, index) => {
    console.log(value, index);
    let date1 = [...date];
    date1[index] = value;
    setDate(date1);
    if (index === 0) {
      setPersonalAccident({
        ...PersonalAccident,
        Self_DOB: value,
      });
    } else {
      setPersonalAccident({
        ...PersonalAccident,
        Spouse_DOB: value,
      });
    }
  };

  const [Contactcod, setContactcod] = useState("");
  const [ContactError, setContactError] = useState("");
  const handleContactcod = (event) => {
    const { value } = event.target;
    const isValid = /^[0-9]*$/.test(event.key) || /^$/.test(event.key);

    if (isValid) {
      setContactcod(value);
      setContactError(""); // Reset error message
    } else {
      event.preventDefault();
      setContactError("Contact should contain only numbers.");
    }

    if (value.length !== 10) {
      setContactError("Contact number should be 10 digits long.");
    } else {
      setContactError("");
    }
  };

  return (
    <div>
      {toogle === false ? (
        <>
           <div className="container-fluid mb-5 ">
            <div className="row align-items-center">
              {/* Logo Section */}
              <div className="col-8 col-lg-3 mt-3 ps-5 d-flex align-items-center" onClick={() => {
                    navigateTo("/");
                  }}>
                <img
                  src={logo}
                  alt="Logo"
                  style={{ width: "12vw", maxWidth: "50px" }}
                />
                <span className="fs-5 ms-2">Policy Dekhoo</span>
              </div>

              {/* Navigation Links */}
              <div className="col-lg-7 col-4 d-flex justify-content-end align-items-center">
                <div className="row align-items-center w-100">
                  {/* Desktop Menu */}
                  <div className="col-lg-9 d-none d-lg-block">
                    <ul className="list-inline mb-0">
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigateTo("/");
                        }}
                      >
                        Home
                      </li>
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        PD Advantages
                      </li>
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Customer Rating
                      </li>
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigateTo("/About")}
                      >
                        About Us
                      </li>
                      <li className="list-inline-item dropdown p-1 fs-6">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ cursor: "pointer" }}
                        >
                          Support
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                          style={{ minWidth: "200px" }}
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              <img src={Callsupport} className="me-2" alt="" />
                              Call Us
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="mailto:info@policydekhoo.com"
                            >
                              <img
                                src={Mailsupport}
                                className="me-2"
                                alt="Mail Support"
                              />
                              Mail Us
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://wa.me/7734007799"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={Whatupsupport}
                                className="me-2"
                                alt="WhatsApp Support"
                              />
                              WhatsApp
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleOpen();
                              }}
                            >
                              <img
                                src={Callback}
                                className="me-2"
                                alt="Callback Icon"
                              />
                              Call Back
                            </a>
                          </li>

                          {/* Modal */}
                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              {/* Modal Content */}
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                              >
                                Request a Call Back
                              </Typography>
                              <Box
                                component="form"
                                sx={{
                                  mt: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 2,
                                }}
                              >
                                {/* Phone Number Input */}
                                <TextField
                                  id="phone-number"
                                  label="Phone Number"
                                  variant="outlined"
                                  fullWidth
                                  // onKeyPress={handleNumericOnly}
                                  maxLength={10}
                                />

                                {/* Reason Textarea */}
                                <TextField
                                  id="reason"
                                  label="Reason"
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  rows={4}
                                />

                                {/* Buttons */}
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    gap: 1,
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      // Add your submission logic here
                                      handleClose();
                                    }}
                                  >
                                    Request Call
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleClose}
                                  >
                                    Close
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </Modal>
                        </ul>
                      </li>
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigateTo("/Claim")}
                      >
                        Claim
                      </li>
                    </ul>
                  </div>

                  {/* Mobile Menu */}
                  <div className="col-lg-3 col-4 d-lg-none text-end">
                    <img
                      src={hambergar}
                      alt="Menu"
                      style={{
                        cursor: "pointer",
                        width: "30px",
                        height: "30px",
                      }}
                      onClick={() => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>

              {/* Contact Section */}
              <div className="col-lg-2 d-none d-lg-flex  justify-content-end pe-5">
                <span
                  className="fs-6 fw-bold "
                  style={{
                    cursor: "pointer",
                    borderBottom: "2px solid black", // Adjust thickness and color as needed
                    // paddingBottom: "4px",
                  }}
                >
                  Contact
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === true ? (
        <>
          <div className="container menuu">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-8  text-start ps-5">
                    <span className="fs-3">Policy dekhoo</span>
                  </div>
                  <div className="col-4">
                    <img
                      src={cross}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-7 col-1">
                <ul
                  className=""
                  style={{ listStyle: "none", textAlign: "left" }}
                >
                  <li className=" p-2  fs-6" style={{ cursor: "pointer" }}  onClick={() => navigateTo("/")}>
                    Home
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    PD Advantages
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Customer Rating
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}  onClick={() => navigateTo("/About")}>
                    About Us
                  </li>
                  <li className="list-inline-item dropdown p-1 fs-6">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ cursor: "pointer" }}
                        >
                          Support
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                          style={{ minWidth: "200px" }}
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              <img src={Callsupport} className="me-2" alt="" />
                              Call Us
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="mailto:info@policydekhoo.com"
                            >
                              <img
                                src={Mailsupport}
                                className="me-2"
                                alt="Mail Support"
                              />
                              Mail Us
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://wa.me/7734007799"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={Whatupsupport}
                                className="me-2"
                                alt="WhatsApp Support"
                              />
                              WhatsApp
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleOpen();
                              }}
                            >
                              <img
                                src={Callback}
                                className="me-2"
                                alt="Callback Icon"
                              />
                              Call Back
                            </a>
                          </li>

                          {/* Modal */}
                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              {/* Modal Content */}
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                              >
                                Request a Call Back
                              </Typography>
                              <Box
                                component="form"
                                sx={{
                                  mt: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 2,
                                }}
                              >
                                {/* Phone Number Input */}
                                <TextField
                                  id="phone-number"
                                  label="Phone Number"
                                  variant="outlined"
                                  fullWidth
                                  // onKeyPress={handleNumericOnly}
                                  maxLength={10}
                                />

                                {/* Reason Textarea */}
                                <TextField
                                  id="reason"
                                  label="Reason"
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  rows={4}
                                />

                                {/* Buttons */}
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    gap: 1,
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      // Add your submission logic here
                                      handleClose();
                                    }}
                                  >
                                    Request Call
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleClose}
                                  >
                                    Close
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </Modal>
                        </ul>
                      </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}  onClick={() => navigateTo("/Claim")}>
                    Claim
                  </li>
                </ul>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <span className="get p-2" onClick={scrollToContactForm}>Contact Us</span>
              </div>
            </div>

            <div className="row fixed-bottom">
              <div className="col-12">
                <div className=" ">
                  <span>© 2020 Policy Dekhoo | Powered by Policy Dekhoo</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {toogle === false ? (
        <>
          <div className="container-fluid">
            <div className="row ps-5">
              <div className="col-sm-10 ">
                <div className="text-start  mx-5">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigateTo("/");
                    }}
                  >
                    Home&#62;
                  </span>
                  <span
                    onClick={() => {
                      navigateTo("/Personalloanform");
                    }}
                  >
                    Personal Accident
                  </span>
                  <span>Personal Detail</span>
                </div>
              </div>
            </div>

            <form action="">
              <div className="row ps-5">
                <div className="col-sm-7 ps-5 ">
                  <div className="">
                    <div className="row">
                      <div className="col-sm-4 col-4">
                        <hr
                          style={{ height: "2px", border: "" }}
                          className="w-100"
                        />
                      </div>
                      <div className="col-sm-4 col-4">
                        <hr
                          style={{ height: "2px", color: "##2D75E0" }}
                          className="w-100"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="heade">
                    <div className=" ">
                      <p className="fs-2" style={{ textAlign: "left" }}>
                        Protect Yourself with Insurance Catered to{" "}
                        <span className="fw-bold">your requirements.</span>
                      </p>
                    </div>

                    <div className="row">
                      {familyArr.map((member, index) => (
                        <div className="col-6" key={index}>
                          <img
                            src={member === "male" ? male : female}
                            alt={member}
                          />
                          <label>{member}</label>
                          <input
                            type="date"
                            className="form-control"
                            name="Self_DOB"
                            value={date[index]}
                            onChange={(e) => {
                              changeDate(e.target.value, index);
                            }}
                          />
                        </div>
                      ))}
                    </div>

                    <div className="row mt-4">
                      <div className="col-md-6 ">
                        <div className="">
                          <label htmlFor="" className="fw-bold">
                            Any member with medical condition
                            <div>
                            <label htmlFor="" className="from-label fw-bold">
                            चिकित्सीय स्थिति वाला कोई भी सदस्य
                            </label>
                          </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 ">
                        <input
                          type="Radio"
                          id=""
                          className="form-input "
                          checked={selectYes === "Yes" ? "true" : null}
                          onClick={() => handleRadioClick("Yes")}
                          required
                          name="Medical_Condition"
                          value={PersonalAccident.Medical_Condition}
                          //  onChange={(e) => {
                          //      addValues(e);
                          //      }}
                        />
                        <label htmlFor="" className="ps-2">
                          Yes
                          <div>
                            <label htmlFor="" className="from-label fw-bold">
                            हां 
                            </label>
                          </div>
                        </label>
                      </div>
                      <div className="col-md-2 ">
                        <input
                          type="Radio"
                          id=""
                          className="form-input "
                          checked={selectYes === "No" ? "true" : null}
                          onClick={() => handleRadioClick("No")}
                          required
                          name="Medical_Condition"
                          value={PersonalAccident.Medical_Condition}
                          //  onChange={(e) => {
                          //        addValues(e);
                          //     }}
                        />
                        <label htmlFor="" className="ps-2 mb-3">
                          No
                          <div>
                            <label htmlFor="" className="from-label fw-bold">
                            नहीं
                            </label>
                          </div>
                        </label>
                      </div>
                    </div>

                    {/* <div className="row"> */}
                    <div className="col-md-10 text-start">
                      <label htmlFor="" className="fw-bold mb-3">
                        Name
                        <div>
                            <label htmlFor="" className="from-label fw-bold">
                            नाम
                            </label>
                          </div>
                      </label>
                      <input
                        type="text"
                        id=""
                        className="p-3 form-control"
                        placeholder="Enter Name"
                        required
                        name="Your_Name"
                        value={PersonalAccident.Your_Name}
                        onChange={(e) => {
                          addValues(e);
                        }}
                      />
                    </div>
                    {/* </div> */}

                    <div className="row">
                      <div className="col-md-10 text-start">
                        <label htmlFor="" className="fw-bold mb-3 mt-3">
                          Mobile
                          <div>
                            <label htmlFor="" className="from-label fw-bold">
                            मोबाइल
                            </label>
                          </div>
                        </label>
                        <input
                          type="text"
                          id=""
                          className="p-3 form-control"
                          placeholder="Enter Mobile No."
                          required
                          name="Mobile_No"
                          value={PersonalAccident.Mobile_No}
                          onChange={(e) => {
                            addValues(e);
                            handleContactcod(e)
                          }}
                          maxLength={"10"}
                          onKeyPress={handleContactcod}
                        />
                        {ContactError && (
                          <span style={{ color: "red" }}>{ContactError}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                  <div className="mt-5">
                    <button
                      className="but19 w-25 p-3 fs-5"
                      onClick={() => {
                        handlesubmit2();
                        if (handleNextButtonClick()) {
                          navigateTo(
                            "/PersonalLoanForm/PersonalForm2/ThankYou"
                          );
                        }
                      }}
                    >
                      Proceed
                    </button>
                  </div>
                </div>

                <div className="col-sm-4 ">
                  <div className="">
                    <img src={doc} alt="" />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="container-fluid">
            <div className="row  mx-5">
              <div className="col-sm-12 text-start ">
                <h2>Life Insurance Plan</h2>
              </div>
              <div className="row ">
                <div className="col-sm-12  ">
                  <div className="text-start ">
                    <p className="fs-4">
                      Safeguard yourself and your loved ones from the financial
                      repercussions of accidents with Policy dekhoo's accident
                      insurance. Our policies offer coverage for accidental
                      death, permanent total disability, temporary total
                      disability, and medical expenses resulting from accidents.
                    </p>
                  </div>

                  <div className="text-start w-75">
                    <p className="fs-4">
                      Choose from a variety of coverage options and add-ons to
                      tailor your policy to your specific needs. With Policy
                      dekhoo, you can rest assured knowing that you are
                      financially protected in the event of an unforeseen
                      accident. you and your loved ones are covered with Policy
                      dekhoo's reliable accident insurance.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="container-fluid">
            <div className="row px-5 mb-5 ms-3">
              <div className="col-sm-12">
                <h5 style={{ textAlign: "justify" }} className="fs-1 ">
                  FAQ
                </h5>
              </div>
            </div>
            <div className="row px-5 ms-3">
              <div className="col-sm-8 ">
                <div className="row ">
                  <div className="col-sm-12">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Why do I need insurance?
                        </Accordion.Header>
                        <Accordion.Body>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          How is insurance premium calculated?
                        </Accordion.Header>
                        <Accordion.Body>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="3">
                        <Accordion.Header>
                          What is a waiting period in insurance?
                        </Accordion.Header>
                        <Accordion.Body>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="4">
                        <Accordion.Header>
                          How do I file an insurance claim?
                        </Accordion.Header>
                        <Accordion.Body>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="5">
                        <Accordion.Header>
                          Can I customize my insurance coverage?
                        </Accordion.Header>
                        <Accordion.Body>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non proident, sunt in culpa qui officia
                          deserunt mollit anim id est laborum.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>

              <div className="col-sm-4 ">
                <div>
                  <img src={docques} alt="" />
                </div>
              </div>
            </div>
          </div> */}

<footer className="container-fluid backgroundtheme mt-5 ">
            <div className="row p-5">
              {/* Logo Section */}
              <div className="col-md-7 text-center text-md-start mb-4 mb-md-0">
                <div className="mb-3 " onClick={() => {
                            navigateTo("/");
                          }}
                        >
                  <img
                    src={Footerlogo}
                    alt="Footer Logo"
                    style={{ maxWidth: "8vw" }}
                    className="footerlog"
                  />
                </div>
                <div className=" justify-content-center align-items-center w-50 d-none d-sm-block">
                  <span className="text-white">
                    Trust us for peace of mind and reliable protection, backed
                    by our unwavering commitment to your satisfaction and
                    security.
                  </span>
                </div>
                <div className=" justify-content-center align-items-center d-block d-sm-none">
                  <span className="text-white">
                    Trust us for peace of mind and reliable protection, backed
                    by our unwavering commitment to your satisfaction and
                    security.
                  </span>
                </div>
              </div>

              {/* Navigation Links */}
              <div className="col-md-5 mt-4	d-none d-sm-block">
                <div className="row">
                  {/* First Column */}
                  <div className="col-sm-6 text-center text-sm-start mb-4 mb-sm-0">
                    <ul className="list-unstyled ">
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                        onClick={() => navigateTo("/")}
                      >
                        Home
                      </li>
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        PD Advantages
                      </li>
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        Customer Rating
                      </li>
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                        onClick={() => navigateTo("/About")}
                      >
                        About Us
                      </li>
                    </ul>
                  </div>

                  {/* Second Column */}
                  <div className="col-sm-6 text-center text-sm-start">
                    <ul className="list-unstyled ">
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        Support
                      </li>
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                        onClick={() => navigateTo("/Claim")}
                      >
                        Claim
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </footer>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h5 className="fs-6">
                All Rights Reserved | Developed By {" "}
                  <a
                    href="https://www.unairesync.com/"
                    className="link"
                    target="blank"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Unaire Sync
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default PersonalForm2;
