import React from "react";
import { useState } from "react";
import Newlogo from "../resources/Logo.svg";
import hamberger from "../resources/hambarger.svg";
import Callsupport from "../resources/callsupport.svg";
import Callback from "../resources/callbacksupport.svg";
import Whatupsupport from "../resources/whatupsupport.svg";
import Mailsupport from "../resources/mailsupport.svg";
import Footerlogo from "../resources/pollicydekhoo-Cdr-jpg-300x300 1 (1).png";
import cross from "../resources/cross.svg";
import "./Claim.css";
import { Modal, Box, Typography, Button, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

function Clam() {
  const location = useLocation();

  console.log(location.state, "claimtype");

  const [toogle, settoggle] = useState(false);
  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
  };

  const [name, setname] = useState();
  const handleAlphaOnly = (event) => {
    const { value } = event.target;

    const isnot = /^[a-zA-Z]*$/.test(event.key);
    const isnot2 = /^$/.test(event.key);
    if (isnot || isnot2) {
      setname(value);
    } else {
      event.preventDefault();
    }
  };

  const [pincode, setPincode] = useState("");
  const handleNumericOnly = (event) => {
    const { value } = event.target;

    const isnot = /^[0-9]*$/.test(event.key);
    const isnot2 = /^$/.test(event.key);
    if (isnot || isnot2) {
      setPincode(value);
    } else {
      event.preventDefault();
    }
  };
  const [language, setLanguage] = useState("en");
  const toggleLanguage = () => {
    setLanguage((prev) => (prev === "en" ? "hi" : "en"));
  };

  const [Claim_form, setClaim_form] = useState({
    idClam_information: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    select_company: "",
    clam_informationcol: location.state,
  });

  const addValues = (e) => {
    setClaim_form({
      ...Claim_form,
      [e.target.name]: e.target.value,
    });
    console.log(Claim_form);
  };

  console.log(Claim_form);

  const handlesubmitClaim = async (e) => {
    console.log(Claim_form);
    try {
      const response = await fetch(
        // "https://policydekhoo-backend.onrender.com/Setcontactformdata",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(Claim_form),
        }
      );

      if (response) {
        console.log("Data added successfully");
      } else {
        console.error("Failed to add data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="backcolou">
      <div className="container-fluid d-none d-lg-block">
        <div className="row">
          <div className="col-sm-8 ps-5 text-start mt-4 mb-3">
            <select name="" id="" className="lang">
              <option value="">English</option>
              <option value="">Hindi</option>
            </select>
          </div>
          <div className="col-sm-2 mt-4">
            <div>
              <span>+917734007799</span>
            </div>
          </div>
          <div className="col-sm-2 mt-4 text-start">
            <div className="">info@policydekhoo.com</div>
          </div>
        </div>
      </div>
      {toogle === false ? (
        <>
          <div className="container-fluid mb-5 ">
            <div className="row align-items-center">
              {/* Logo Section */}
              <div className="col-8 col-lg-3 mt-3 ps-5 d-flex align-items-center">
                <img
                  src={Newlogo}
                  alt="Logo"
                  style={{ width: "12vw", maxWidth: "50px" }}
                  onClick={() => navigateTo("/")}
                />
                <span className="fs-5 ms-2">Policy Dekhoo</span>
              </div>

              {/* Navigation Links */}
              <div className="col-lg-7 col-4 d-flex justify-content-end align-items-center">
                <div className="row align-items-center w-100">
                  {/* Desktop Menu */}
                  <div className="col-lg-9 d-none d-lg-block">
                    <ul className="list-inline mb-0">
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigateTo("/")}
                      >
                        Home
                      </li>
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        PD Advantages
                      </li>
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                      >
                        Customer Rating
                      </li>
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                        // onClick={() => navigateTo("/About")}
                      >
                        About Us
                      </li>
                      <li className="list-inline-item dropdown p-1 fs-6">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ cursor: "pointer" }}
                        >
                          Support
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                          style={{ minWidth: "200px" }}
                        >
                          <li>
                            <a className="dropdown-item" href="#">
                              <img src={Callsupport} className="me-2" alt="" />
                              Call Us
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="mailto:info@policydekhoo.com"
                            >
                              <img
                                src={Mailsupport}
                                className="me-2"
                                alt="Mail Support"
                              />
                              Mail Us
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="https://wa.me/7734007799"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={Whatupsupport}
                                className="me-2"
                                alt="WhatsApp Support"
                              />
                              WhatsApp
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleOpen();
                              }}
                            >
                              <img
                                src={Callback}
                                className="me-2"
                                alt="Callback Icon"
                              />
                              Call Back
                            </a>
                          </li>

                          {/* Modal */}
                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              {/* Modal Content */}
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                              >
                                Request a Call Back
                              </Typography>
                              <Box
                                component="form"
                                sx={{
                                  mt: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 2,
                                }}
                              >
                                {/* Phone Number Input */}
                                <TextField
                                  id="phone-number"
                                  label="Phone Number"
                                  variant="outlined"
                                  fullWidth
                                  onKeyPress={handleNumericOnly}
                                  maxLength={10}
                                />

                                {/* Reason Textarea */}
                                <TextField
                                  id="reason"
                                  label="Reason"
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  rows={4}
                                />

                                {/* Buttons */}
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    gap: 1,
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      // Add your submission logic here
                                      handleClose();
                                    }}
                                  >
                                    Request Call
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleClose}
                                  >
                                    Close
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </Modal>
                        </ul>
                      </li>
                      <li
                        className="list-inline-item p-1 fs-6"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigateTo("/Claim")}
                      >
                        Claim
                      </li>
                    </ul>
                  </div>

                  {/* Mobile Menu */}
                  <div className="col-lg-3 col-4 d-lg-none text-end">
                    <img
                      src={hamberger}
                      alt="Menu"
                      style={{
                        cursor: "pointer",
                        width: "30px",
                        height: "30px",
                      }}
                      onClick={() => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>

              {/* Contact Section */}
              <div className="col-lg-2 d-none d-lg-flex  justify-content-end pe-5">
                <span
                  className="fs-6 fw-bold "
                  style={{
                    cursor: "pointer",
                    borderBottom: "2px solid black", // Adjust thickness and color as needed
                    // paddingBottom: "4px",
                  }}
                >
                  Contact
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === true ? (
        <>
          <div className="container menuu">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-8  text-start ps-5">
                    <span className="fs-3">Policy dekhoo</span>
                  </div>
                  <div className="col-4">
                    <img
                      src={cross}
                      alt=""
                      onClick={(e) => settoggle(!toogle)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-7 col-1">
                <ul
                  className=""
                  style={{ listStyle: "none", textAlign: "left" }}
                >
                  <li
                    className=" p-2  fs-6"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigateTo("/")}
                  >
                    Home
                  </li>
                  <li className=" p-2 fs-6" style={{ cursor: "pointer" }}>
                    PD Advantages
                  </li>
                  <li className="p-2 fs-6" style={{ cursor: "pointer" }}>
                    Customer Rating
                  </li>
                  <li
                    className=" p-2 fs-6"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigateTo("/About")}
                  >
                    About Us
                  </li>
                  <li className="list-inline-item dropdown p-1 fs-6">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ cursor: "pointer" }}
                    >
                      Support
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                      style={{ minWidth: "200px" }}
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          <img src={Callsupport} className="me-2" alt="" />
                          Call Us
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="mailto:info@policydekhoo.com"
                        >
                          <img
                            src={Mailsupport}
                            className="me-2"
                            alt="Mail Support"
                          />
                          Mail Us
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="https://wa.me/7734007799"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={Whatupsupport}
                            className="me-2"
                            alt="WhatsApp Support"
                          />
                          WhatsApp
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleOpen();
                          }}
                        >
                          <img
                            src={Callback}
                            className="me-2"
                            alt="Callback Icon"
                          />
                          Call Back
                        </a>
                      </li>

                      {/* Modal */}
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          {/* Modal Content */}
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            Request a Call Back
                          </Typography>
                          <Box
                            component="form"
                            sx={{
                              mt: 2,
                              display: "flex",
                              flexDirection: "column",
                              gap: 2,
                            }}
                          >
                            {/* Phone Number Input */}
                            <TextField
                              id="phone-number"
                              label="Phone Number"
                              variant="outlined"
                              fullWidth
                              // onKeyPress={handleNumericOnly}
                              maxLength={10}
                            />

                            {/* Reason Textarea */}
                            <TextField
                              id="reason"
                              label="Reason"
                              variant="outlined"
                              fullWidth
                              multiline
                              rows={4}
                            />

                            {/* Buttons */}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                gap: 1,
                              }}
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  // Add your submission logic here
                                  handleClose();
                                }}
                              >
                                Request Call
                              </Button>
                              <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleClose}
                              >
                                Close
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Modal>
                    </ul>
                  </li>
                  <li
                    className="p-2 fs-6"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigateTo("/Claim")}
                  >
                    Claim
                  </li>
                </ul>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <span className="get p-2">Contact Us</span>
              </div>
            </div>

            <div className="row fixed-bottom ">
              <div className="col-12">
                <div className=" ">
                  <span>© 2020 Policy Dekhoo | Powered by Policy Dekhoo</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === false ? (
        <>
          <div className="container formcolour">
            <div
              className="card  mx-auto p-4 "
              style={{ boxShadow: "1px 1px 6px rgba(0, 0, 0, 0.2)" }}
            >
              <div className="row">
                <div className="col-12">
                  <h1 className="form-title mb-4">Claim</h1>
                </div>
              </div>
              <form>
                {/* Name Fields */}
                <div className="row mb-3">
                  {/* First Name */}
                  <div className="col-md-6 text-start">
                    <label htmlFor="firstName" className="form-label">
                      First Name
                    </label>
                    <div>
                      <label htmlFor="" className=" form-label fw-bold">
                        प्रथम नाम दर्ज करें
                      </label>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      placeholder="Enter Your First Name"
                      onKeyPress={handleAlphaOnly}
                      name="first_name"
                      value={Claim_form.first_name}
                      onChange={(e) => {
                        addValues(e);
                      }}
                    />
                  </div>
                  {/* Last Name */}
                  <div className="col-md-6 text-start">
                    <label htmlFor="lastName" className="form-label">
                      Last Name
                    </label>
                    <div className="">
                      <label htmlFor="" className=" form-label fw-bold">
                        अंतिम नाम दर्ज करो
                      </label>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      placeholder="Enter Your Last Name"
                      onKeyPress={handleAlphaOnly}
                      name="last_name"
                      value={Claim_form.last_name}
                      onChange={(e) => {
                        addValues(e);
                      }}
                    />
                  </div>
                </div>

                {/* Contact Number */}
                <div className="mb-3 text-start">
                  <label htmlFor="contactNumber" className="form-label">
                    Contact Number
                  </label>
                  <div className="">
                    <label htmlFor="" className="form-label fw-bold">
                      मोबाइल नंबर दर्ज करें
                    </label>
                  </div>
                  <div className="input-group">
                    <span className="input-group-text">
                      <img
                        src="https://flagcdn.com/w40/in.png"
                        alt="India Flag"
                        style={{ height: "20px", marginRight: "5px" }}
                      />
                      +91
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      id="contactNumber"
                      placeholder="Enter Your Contact Number"
                      onKeyPress={handleNumericOnly}
                      maxLength={10}
                      name="phone_number"
                      value={Claim_form.phone_number}
                      onChange={(e) => {
                        addValues(e);
                      }}
                    />
                  </div>
                </div>

                {/* Select Insurance Company */}
                <div className="mb-4 text-start">
                  <label
                    htmlFor="insuranceCompany"
                    className="form-label"
                    name="select_company"
                  >
                    Select Insurance Company
                  </label>
                  <div>
                    <label htmlFor="" className="from-label fw-bold">
                      बीमा कंपनी का चयन करें
                    </label>
                  </div>
                  <select
                    className="form-select"
                    id="insuranceCompany"
                    name="select_company"
                    value={Claim_form.select_company}
                    onChange={(e) => {
                      addValues(e);
                    }}
                  >
                    <option value="">
                      {language === "en"
                        ? " Select Insurance Company"
                        : "बीमा कंपनी का चयन करें"}
                    </option>
                    <option value="Acko General Insurance Limited">
                      {language === "en"
                        ? "Acko General Insurance Limited"
                        : "Acko जनरल इंश्योरेंस लिमिटेड"}
                    </option>
                    <option value="Insurance Company of India Limited">
                      {language === "en"
                        ? "Insurance Company of India Limited"
                        : "भारत इंश्योरेंस कंपनी लिमिटेड"}
                    </option>
                    <option value="Bajaj Allianz General Insurance Company Limited">
                      {language === "en"
                        ? "Bajaj Allianz General Insurance Company Limited"
                        : "बजाज आलियांज जनरल इंश्योरेंस कंपनी लिमिटेड"}
                    </option>
                    <option value="HDFC ERGO General Insurance Company Limited">
                      {language === "en"
                        ? "HDFC ERGO General Insurance Company Limited"
                        : "एचडीएफसी ईआरजीओ जनरल इंश्योरेंस कंपनी लिमिटेड"}
                    </option>
                    <option value="ICICI Lombard General Insurance Company Limited">
                      {language === "en"
                        ? "ICICI Lombard General Insurance Company Limited"
                        : "आईसीआईसीआई लोम्बार्ड जनरल इंश्योरेंस कंपनी लिमिटेड"}
                    </option>
                    <option value="Kotak Mahindra General Insurance Company Limited">
                      {language === "en"
                        ? "Kotak Mahindra General Insurance Company Limited"
                        : "कोटक महिंद्रा जनरल इंश्योरेंस कंपनी लिमिटेड"}
                    </option>
                    <option value="Max Bupa Health Insurance Company Limited">
                      {language === "en"
                        ? "Max Bupa Health Insurance Company Limited"
                        : "मैक्स बुपा हेल्थ इंश्योरेंस कंपनी लिमिटेड"}
                    </option>
                    <option value="New India Assurance Company Limited">
                      {language === "en"
                        ? "New India Assurance Company Limited"
                        : "न्यू इंडिया एश्योरेंस कंपनी लिमिटेड"}
                    </option>
                    <option value="Religare Health Insurance Company Limited">
                      {language === "en"
                        ? "Religare Health Insurance Company Limited"
                        : "रिलigare हेल्थ इंश्योरेंस कंपनी लिमिटेड"}
                    </option>
                    <option value="SBI General Insurance Company Limited">
                      {language === "en"
                        ? "SBI General Insurance Company Limited"
                        : "एसबीआई जनरल इंश्योरेंस कंपनी लिमिटेड"}
                    </option>
                    <option value="Tata AIG General Insurance Company Limited">
                      {language === "en"
                        ? "Tata AIG General Insurance Company Limited"
                        : "टाटा एआईजी जनरल इंश्योरेंस कंपनी लिमिटेड"}
                    </option>
                    <option value="The Oriental Insurance Company Limited">
                      {language === "en"
                        ? "The Oriental Insurance Company Limited"
                        : "द ओरिएंटल इंश्योरेंस कंपनी लिमिटेड"}
                    </option>
                    <option value="United India Insurance Company Limited">
                      {language === "en"
                        ? "United India Insurance Company Limited"
                        : "यूनाइटेड इंडिया इंश्योरेंस कंपनी लिमिटेड"}
                    </option>
                    <option value="Wealth Insurance Company Limited">
                      {language === "en"
                        ? "Wealth Insurance Company Limited"
                        : "वेल्थ इंश्योरेंस कंपनी लिमिटेड"}
                    </option>
                  </select>
                </div>

                {/* Submit Button */}
                <div className="text-center mb-5">
                  <button
                    type="submit"
                    className="btn btn-primary "
                    onClick={handlesubmitClaim}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
          <footer className="container-fluid backgroundtheme mt-5 ">
            <div className="row p-5">
              {/* Logo Section */}
              <div className="col-md-7 text-center text-md-start mb-4 mb-md-0">
                <div className="mb-3">
                  <img
                    src={Footerlogo}
                    alt="Footer Logo"
                    style={{ maxWidth: "8vw" }}
                    className="footerlog"
                  />
                </div>
                <div className=" justify-content-center align-items-center w-50 d-none d-sm-block">
                  <span className="text-white">
                    Trust us for peace of mind and reliable protection, backed
                    by our unwavering commitment to your satisfaction and
                    security.
                  </span>
                </div>
                <div className=" justify-content-center align-items-center d-block d-sm-none">
                  <span className="text-white">
                    Trust us for peace of mind and reliable protection, backed
                    by our unwavering commitment to your satisfaction and
                    security.
                  </span>
                </div>
              </div>

              {/* Navigation Links */}
              <div className="col-md-5 mt-4 	d-none d-sm-block">
                <div className="row">
                  {/* First Column */}
                  <div className="col-sm-6 text-center text-sm-start mb-4 mb-sm-0">
                    <ul className="list-unstyled ">
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                        onClick={() => navigateTo("/")}
                      >
                        Home
                      </li>
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        PD Advantages
                      </li>
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        Customer Rating
                      </li>
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                        onClick={() => navigateTo("/About")}
                      >
                        About Us
                      </li>
                    </ul>
                  </div>

                  {/* Second Column */}
                  <div className="col-sm-6 text-center text-sm-start">
                    <ul className="list-unstyled ">
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                      >
                        Support
                      </li>
                      <li
                        className="mb-3 fw-bold fs-6"
                        style={{ cursor: "pointer", color: "white" }}
                        onClick={() => navigateTo("/Claim")}
                      >
                        Claim
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </footer>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div>
                  <a
                    href="https://unairesync.com/"
                    style={{ color: "black", textDecoration: "none" }}
                    className="fs-6 "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    All Rights Reserved | Developed By UnaireSync
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default Clam;
