import React from "react";
import { Route, Routes } from "react-router-dom";
import BikeInsureance2 from "./BikeIndurance/BikeInsureance2";
import Landingpage from "./Landingpage/Landingpage";
import BikeInsuresnce from "./BikeIndurance/BikeInsuresnce";
import BikeInsureance3 from "./BikeIndurance/BikeInsureance3";
import CarForm from "./CarInsurance/CarForm";
import CarForm2 from "./CarInsurance/CarForm2";
import CarForm3 from "./CarInsurance/CarForm3";
import Travel from "./Travel/TravelForm";
import GroupInsuranceform from "./GroupInsurance/GroupInsuranceform";
import Lifeinsuranceform from "./LifeInsurance/LifeInsuranceform";
import Personalloneform from "./PersonalAccident/Personalloneform";
import PersonalForm2 from "./PersonalAccident/PersonalForm2";
import ThankYou from "./Thank You/ThankYou";
import CreditCardForm1 from "./CredtCard/CreditCardForm1";
import CreditCardForm2 from "./CredtCard/CreditCardForm2";
import CreditCardForm3 from "./CredtCard/CreditCardForm3";
import LoanForm from "./PersonalLoan/LoanForm";
import LoanForm2 from "./PersonalLoan/LoanForm2";
import LoanForm3 from "./PersonalLoan/LoanForm3";
import HealthForm1 from "./Healthinsurance/HealthForm1";
import Healthform2 from "./Healthinsurance/Healthform2";
import PageNotFound from "./PageNotFound/PageNotFound";
import HomeShop from "./Homeshop/HomeShop";
import LifeInsuranceform2 from "./LifeInsurance/LifeInsuranceform2";
import AboutUs from "./Aboutus/About";
import Landingpagenew from "./NewLandingPage/Landingpagenew";
import Clamin from "./Claim/Clamin";
import Clam from "./Claim/Clam";

function RoutingFile() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Landingpagenew />} />

        <Route path="/Claim" element={<Clamin />} />
        <Route path="/Claim/Clam" element={<Clam/>}/>

        <Route path="/BikeInsurance" element={<BikeInsuresnce />} />
        <Route
          path="/BikeInsurance/BikeInsurance2/:obj"
          element={<BikeInsureance2 />}
        />
        <Route
          path="/BikeInsurance/BikeInsurance2/BikeInsurance3/:obj"
          element={<BikeInsureance3 />}
        />
        <Route
          path="/BikeInsurance/BikeInsurance2/BikeInsurance3/ThankYou"
          element={<ThankYou />}
        />

        <Route path="/CarForm/:obj" element={<CarForm />} />
        <Route path="/CarForm/CarForm2/:obj" element={<CarForm2 />} />
        <Route path="/CarForm/CarForm2/CarForm3/:obj" element={<CarForm3 />} />
        <Route
          path="/CarForm/CarForm2/CarForm3/ThankYou"
          element={<ThankYou />}
        />

        <Route path="/TravelForm" element={<Travel />} />
        <Route path="/Travel/ThankYou" element={<ThankYou />} />

        <Route path="/About" element={<AboutUs />} />

        <Route path="/GroupInsurance" element={<GroupInsuranceform />} />
        <Route path="/GroupInsuranceform/ThankYou" element={<ThankYou />} />

        <Route path="/LifeInsurance" element={<Lifeinsuranceform />} />
        <Route
          path="/LifeInsuranceform/LifeInsuranceform2/:obj"
          element={<LifeInsuranceform2 />}
        />
        <Route
          path="/LifeInsuranceform/LifeInsuranceform2/ThankYou"
          element={<ThankYou />}
        />

        <Route path="/Homeshop" element={<HomeShop />} />
        <Route path="/HomeShop/ThankYou" element={<ThankYou />} />

        <Route path="/PersonalLoan" element={<Personalloneform />} />
        <Route
          path="/PersonalLoanform/PersonalForm2/:obj"
          element={<PersonalForm2 />}
        />
        <Route
          path="/PersonalLoanform/PersonalForm2/ThankYou"
          element={<ThankYou />}
        />

        <Route path="/CreditCardForm1" element={<CreditCardForm1 />} />
        <Route
          path="/CreditCardForm1/CreditCardForm2/:obj"
          element={<CreditCardForm2 />}
        />
        <Route
          path="/CreditCardForm1/CreditCardForm2/CreditCardForm3/:obj"
          element={<CreditCardForm3 />}
        />
        <Route
          path="/CreditCardForm1/CreditCardForm2/CreditCardForm3/ThankYou"
          element={<ThankYou />}
        />

        <Route path="/LoanForm" element={<LoanForm />} />
        <Route path="/LoanForm1/ThankYou" element={<ThankYou />} />
        {/* <Route path="/LoanForm1/LoanForm2/LoanForm3" element={<LoanForm3/>}/>
    <Route path="/LoanForm1/LoanForm2/LoanForm3/ThankYou" element={<ThankYou/>}/>     */}

        <Route path="/HealthForm1" element={<HealthForm1 />} />
        <Route path="/HealthForm1/HealthForm2" element={<Healthform2 />} />
        <Route
          path="/HealthForm1/HealthForm2/ThankYou"
          element={<ThankYou />}
        />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default RoutingFile;
